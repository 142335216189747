import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
// MATERIAL-UI
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const styles = theme => ({
    card: {
        margin: theme.spacing.unit * 2
    },
    button: {
        marginLeft: "auto",
        margin: theme.spacing.unit,
        padding: "6px",
        verticalAlign: "bottom"
    },
    smallButton: {
        marginLeft: "auto",
        margin: theme.spacing.unit,
        padding: "6px",
        verticalAlign: "bottom",
        fontSize: "10px"
    },
    playerCard: {
        margin: theme.spacing.unit * 2,
        maxWidth: "600px"
    },
    tableRoot: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
});

class EmbedPlayerDemo extends Component {

    render() {

        const { classes } = this.props;

        let scriptText = '<script src="https://embed.clipcast.app/clipcastEmbedPlayer.js"></script>';
        let cssText = '<link rel="stylesheet" type="text/css" href="https://embed.clipcast.app/clipcastEmbedPlayer.css"/>';
        let divText = '<div id="clipcastEmbedPlayer"></div>';
        let jsText = 'window.ClipcastEmbedPlayer.searchClips("Carson Wentz", {preferredPodcastId: "LATEROUND"});';
        let jsExclusiveText = 'window.ClipcastEmbedPlayer.searchClips("Carson Wentz", {exclusivePodcastIds: ["LATEROUND"]});';
        let colorText = "window.ClipcastEmbedPlayer.setTheme('#004953', '#046A38', '#000', 'My Cool Font')";
        let loadingIconText = "window.ClipcastEmbedPlayer.setCustomLoadingIcon('mywebsite.com/logo.png', 80)"
        let subscribeText = "window.ClipcastEmbedPlayer.setSubscriptionUrls('https://spotify.com/MyPodcast', 'https://podcasts.apple.com/MyPodcast')"

        return (
            <Grid item xs={12} align='center'>
                <Card className={classes.card}>
                    <CardContent>
                        <Grid
                            container
                            direction="column"
                        >
                            <Typography variant={'h5'} style={{textAlign: "center", marginBottom: "4px"}}>
                                Clipcast Embedded Player Demo
                            </Typography>

                            <Typography variant={'h6'} style={{textAlign: "center", marginBottom: "4px"}}>
                                <u>The Basics</u>
                            </Typography>

                            <Typography variant={'p'} style={{textAlign: "center", marginBottom: "4px"}}>
                                Linking your website to Clipcast provides a powerful new tool for your users. They can
                                research players on your site with your content, and then quickly access relevant
                                podcast clips about a player or topic. Searches will always fetch the latest data,
                                meaning you add Clipcast to your site one time and it will always play the latest
                                episodes. Integration is very quick and usually only a slight modification to your
                                website.
                            </Typography>
                            <br/>
                            <Typography variant={'p'} style={{textAlign: "center", marginBottom: "4px"}}>
                                The best way to do this is with our embedded player, which brings Clipcast search
                                directly to your site. Users never leave your site but still get all the benefits
                                of Clipcast.
                            </Typography>
                            <br/>

                            <Typography variant={'p'} style={{textAlign: "center", marginBottom: "4px"}}>
                                To do this, simply create a div on your website and load our javascript files. You
                                first need to create a div like so:
                            </Typography>
                            <br/>
                            <Typography variant={'p'} style={{textAlign: "center", marginBottom: "4px", color: "rgb(120, 120, 120)"}}>
                                <code>{divText}</code>
                            </Typography>
                            <br/>

                            <Typography variant={'p'} style={{textAlign: "center", marginBottom: "4px"}}>
                                Next, load our javascript files. This should be done at the bottom of the body tag. This
                                can be done a variety of ways depending on your code framework:
                            </Typography>

                            <br/>
                            <Typography variant={'p'} style={{textAlign: "center", marginBottom: "4px", color: "rgb(120, 120, 120)"}}>
                                <code>{scriptText}</code>
                            </Typography>
                            <Typography variant={'p'} style={{textAlign: "center", marginBottom: "4px", color: "rgb(120, 120, 120)"}}>
                                <code>{cssText}</code>
                            </Typography>
                            <br/>
                            <Typography variant={'p'} style={{textAlign: "center", marginBottom: "4px"}}>
                                Finally, you can call on the embedded player to open and search clips with the following:
                            </Typography>
                            <br/>
                            <Typography variant={'p'} style={{textAlign: "center", marginBottom: "4px", color: "rgb(120, 120, 120)"}}>
                                <code>window.ClipcastEmbedPlayer.searchClips("Carson Wentz");</code>
                            </Typography>
                            <br/>

                            <Typography variant={'h6'} style={{textAlign: "center", marginBottom: "4px"}}>
                                <u>Preferred and Exclusive Content</u>
                            </Typography>

                            <Typography variant={'p'} style={{textAlign: "center", marginBottom: "4px"}}>
                                Clipcast also allows podcasters to have their podcast show up in the results more often than
                                other podcasts. While it will not show only your podcast results, it will show a
                                majority of them coming from your podcasts. To enable this feature, pass in the
                                preferredPodcastId parameter with your podcast ID. To get your podcast ID, you can email
                                us at hello@clipcast.app. Below is an example of this showing the majority of results
                                from the Late Round podcast (you would need to substitute LATEROUND with your podcast id):
                            </Typography>
                            <br/>
                            <Typography variant={'p'} style={{textAlign: "center", marginBottom: "4px", color: "rgb(120, 120, 120)"}}>
                                <code>{jsText}</code>
                            </Typography>

                            <br/>
                            <Typography variant={'p'} style={{textAlign: "center", marginBottom: "4px"}}>
                                If you have the Clipcast Limited subscription, you are able to play exclusively your
                                content in the embedded player. To do this, simply hand in a list of exclusive podcast
                                IDs using the exclusivePodcastIds paramter as such:
                            </Typography>
                            <br/>
                            <Typography variant={'p'} style={{textAlign: "center", marginBottom: "4px", color: "rgb(120, 120, 120)"}}>
                                <code>{jsExclusiveText}</code>
                            </Typography>

                            <Typography variant={'h6'} style={{textAlign: "center", marginBottom: "4px"}}>
                                <u>Branding</u>
                            </Typography>

                            <br/>
                            <Typography variant={'p'} style={{textAlign: "center", marginBottom: "4px"}}>
                                You can use the Clipcast logo in your button. The link to our preferred brand logo in
                                SVG format (which requires a specific font) is here:
                                <a href="https://clipcast.app/clipcast-horizontal.svg" target="_blank" rel="noopener noreferrer">
                                    https://clipcast.app/clipcast-horizontal.svg
                                </a>
                                <br/>
                                <br/>
                                PNG format (which does not require a specific font) is here:
                                <a href="https://clipcast.app/clipcast-horizontal.png" target="_blank" rel="noopener noreferrer">
                                    https://clipcast.app/clipcast-horizontal.png
                                </a>
                            </Typography>
                            <br/>
                            <Typography variant={'p'} style={{textAlign: "center", marginBottom: "4px"}}>
                                Below is an example of a player card with a Clipcast button. When the button is pressed,
                                the embedded player opens and plays clips.
                            </Typography>

                        </Grid>
                    </CardContent>
                </Card>
                <Card className={classes.playerCard}>
                    <CardContent>
                        <Grid
                            container
                            direction="column"
                        >
                            <div id="clipcastEmbedPlayer"></div>
                            <Typography variant={'h5'} style={{textAlign: "center", marginBottom: "4px"}}>
                                Carson Wentz
                            </Typography>

                            <Grid>
                                <Grid item xs={4} align='center'></Grid>
                                <Grid item xs={4} align='center'>

                                    <img alt="" src="https://d395i9ljze9h3x.cloudfront.net/req/20180910/images/headshots/WentCa00_2018.jpg"></img>
                                </Grid>
                            </Grid>

                            <Typography variant={'p'} style={{textAlign: "center", marginBottom: "4px", color: "rgb(120, 120, 120)"}}>
                                Position: QB Throws: Right
                            </Typography>
                            <Typography variant={'p'} style={{textAlign: "center", marginBottom: "4px", color: "rgb(120, 120, 120)"}}>
                                6' 5", 237lb (196cm, 107kg)
                            </Typography>
                            <Typography variant={'p'} style={{textAlign: "center", marginBottom: "4px", color: "rgb(120, 120, 120)"}}>
                                Born: December 30, 1992 (Age: 26-161d) in Raleigh, NC
                            </Typography>

                            <br/>

                            <Typography variant={'p'} style={{textAlign: "center", marginBottom: "4px"}}>
                                Carson James Wentz is an American professional football player. He plays as a
                                quarterback for the ‘Philadelphia Eagles’ in the ‘National Football League’ (NFL) and
                                is considered by many as one of the best young quarterbacks in the league.
                            </Typography>

                            <br/>

                            <Button
                                variant="outlined"
                                className={classes.button}
                                onClick={this.onPlayerButtonClick}
                            >
                                Search in
                                <img
                                    alt=""
                                    align="center"
                                    src={process.env.PUBLIC_URL + "/clipcast-horizontal.svg"}
                                    height="30px"
                                    style={{ marginLeft: "4px" }}
                                />
                            </Button>

                        </Grid>
                    </CardContent>
                </Card>
                <Card className={classes.card}>
                    <CardContent>
                        <Grid
                            container
                            direction="column"
                        >                            

                            <Typography variant={'h6'} style={{textAlign: "center", marginBottom: "4px"}}>
                                <u>Customizing Look and Feel</u>
                            </Typography>

                            <Typography variant={'p'} style={{textAlign: "center", marginBottom: "4px"}}>
                                You can also specify the colors and font of the embedded player. To do this, you simply call a method on the 
                                player which will set the primary color, the primary light color, and the primary dark color.

                                You can do this with the setTheme method that takes the colors in the order primary, primary light, primary dark.

                                Note when setting the font that you are only specifying a name. Your website must properly load the font separately. 
                                If you do not specify a font, the default will be used.

                                Below is an example to set the colors and font:                                
                            </Typography>
                            <br/>
                            <Typography variant={'p'} style={{textAlign: "center", marginBottom: "4px", color: "rgb(120, 120, 120)"}}>
                                <code>{colorText}</code>
                            </Typography>
                            <br/>
                            <Typography variant={'p'} style={{textAlign: "center", marginBottom: "4px"}}>
                                The three colors affect different parts of the player. The following image shows which colors are used where                                
                            </Typography>
                            <br/>
                            <br/>
                            <br/>
                            <img
                                    alt=""
                                    align="center"
                                    src={process.env.PUBLIC_URL + "/embedPlayerTheme.png"}
                                    height="150px"
                                    style={{ marginLeft: "4px" }}
                                />
                            <br/>
                            <br/>
                            <br/>
                            <Typography variant={'p'} style={{textAlign: "center", marginBottom: "4px"}}>
                                You can also specify an icon to be displayed on the loading screen. You can pass in the url of
                                any image and the height of the icon with the following:                             
                            </Typography>
                            <br/>
                            <Typography variant={'p'} style={{textAlign: "center", marginBottom: "4px", color: "rgb(120, 120, 120)"}}>
                                <code>{loadingIconText}</code>
                            </Typography>
                            <br/>
                            <Typography variant={'p'} style={{textAlign: "center", marginBottom: "4px"}}>
                                Finally, you can provide a subscription link that will link to your podcast subscribe page. This is
                                passed in as two URL's, one for iOS devices and one for every other device. This is done so you can 
                                link iOS devices to iTunes. You can specify this with the following:                     
                            </Typography>
                            <br/>
                            <Typography variant={'p'} style={{textAlign: "center", marginBottom: "4px", color: "rgb(120, 120, 120)"}}>
                                <code>{subscribeText}</code>
                            </Typography>

                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        );
    }

    componentDidMount () {
        const script = document.createElement("script");

        script.src = process.env.REACT_APP_ENV === "prod" ? "https://embed.clipcast.app/clipcastEmbedPlayer.js" : "https://test-embed.clipcast.app/clipcastEmbedPlayer.js";
        script.async = true;

        document.body.appendChild(script);
    }

    onPlayerButtonClick = () => {        
        window.ClipcastEmbedPlayer.searchClips("Carson Wentz");
    }
}

export default withStyles(styles)(EmbedPlayerDemo);