import React from "react";
import PropTypes from "prop-types";
// MATERIAL-UI
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from "@material-ui/core/IconButton";
import ListItem from '@material-ui/core/ListItem';
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import TextField from '@material-ui/core/TextField';
import Tooltip from "@material-ui/core/Tooltip";
import Typography from '@material-ui/core/Typography';
import { withStyles } from "@material-ui/core/styles";
// ICONS
import Add from  '@material-ui/icons/Add';
import Headset from '@material-ui/icons/Headset';
import PlaylistAdd from "@material-ui/icons/PlaylistAdd";
import PlaylistPlay from '@material-ui/icons/PlaylistPlay';
// COLORS
import red from "@material-ui/core/colors/red";

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper
  },
  addListItem: {
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    fontSize: 14
  },
  divider: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  icon :{
    paddingRight: theme.spacing.unit * 2,
  },
  input: {
    fontSize: 14,
  },
  menu: {
    minWidth: 250,
    maxWidth: 250,
    // overflowY: 'scroll',
  },
  queueList: {
    maxHeight: 200,
    overflowY: 'scroll',
  },
  removeCircle: {
    color: red[800]
  },
  textField: {
    width: 218,
  }
});

class AddRemoveListButton extends React.Component {
  state = {
    addingNewPlayList: false,
    anchorEl: null,
    newPlaylistName : '',
    isCreatingPlaylist: false,
  };

  render() {
    if (!this.props) {
      return null;
    }
    const { classes, disabled, userInfo } = this.props;
    const { addingNewPlayList, anchorEl, newPlaylistName } = this.state;
    const { queues = {}, loggedIn } = userInfo;

    if (!loggedIn) {
      return (
        <Tooltip title="Login to add to Playlist" placement="top">
          <div>
            <IconButton
              aria-label="Login to add to playlist"
              onClick={this.handleClickButton}
              disabled={true}
            >
              <PlaylistAdd />
            </IconButton>
          </div>
        </Tooltip>
      );
    }

    // make sure that the 'Listen later queue is present
    const filteredQueues = queues.filter((q) => {
      return q !== 'new';
    })

    const hasQueues = filteredQueues && queues.length > 0; // this might be redundant

    return (
      <div className={classes.root}>
        <Tooltip title="Add to Playlist" placement="top">
          <IconButton
            aria-label="Add to playlist"
            onClick={this.handleClickButton}
            disabled={disabled}
          >
            <PlaylistAdd />
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          className={classes.menu}
          classes={{
            paper: classes.menu
          }}
          id="lock-menu"
          open={Boolean(anchorEl)}
          onClose={this.handleCloseMenu}
        >
          <ListItem dense={true}>
            <Typography variant={'body2'}> 
              Save to ... 
            </Typography>
          </ListItem>
          <ListItem dense={true} divider={true} className={classes.divider} key={'clipcastDivider1'} />
          <MenuItem
            dense={true}
            key={'listenLater'}
            onClick={event => this.handleMenuItemClick(event, 'new')}
          >
            <Headset className={classes.icon} />
            <Typography variant={'body2'}> Listen later</Typography>
          </MenuItem>
          <ListItem divider={true}  className={classes.divider} key={'clipcastDivider2'} />
          <div className={classes.queueList}>
            {hasQueues &&
              filteredQueues.map((queue, index) => (
                <MenuItem
                  dense={true}
                  key={queue}
                  onClick={event => this.handleMenuItemClick(event, index)}
                >
                  <PlaylistPlay className={classes.icon} />
                  <Typography variant={'body2'}> 
                    {queue === "new" ? "Listen later" : queue}
                  </Typography>
                </MenuItem>
              ))
            }
          </div>
          <ListItem divider={true}  className={classes.divider} key={'clipcastDivider3'} />
          {!addingNewPlayList && 
            <MenuItem
              button={true}
              dense={true}
              key={'clipcastAddButton'}
              onClick={this.onClickCreatePlaylist}
            >
              <Add className={classes.icon}/> 
              <Typography variant={'body2'}>
                Create new playlist
              </Typography>
            </MenuItem>
          }
          {addingNewPlayList && 
            <ListItem 
              className={classes.addListItem}
              dense={true}
            >
              <Grid container justify={'space-between'}>
                <Grid item xs={12} >
                  <TextField 
                    className={classes.textField}
                    fullWidth={true}
                    id="newPlaylistName"
                    label="Playlist name"
                    margin="dense"
                    onChange={this.handleTextfieldChange('newPlaylistName')}
                    value={newPlaylistName}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Button
                    aria-label="Login to add to playlist"
                    onClick={this.handleCancelNewPlayList}
                    className={classes.button}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6} >
                  <Button
                    aria-label="Login to add to playlist"
                    onClick={this.onClickConfirmNewPlayList}
                    className={classes.button}
                    disabled={newPlaylistName.length < 1}
                  >
                      {disabled ? 'adding...' : 'Create'}
                  </Button>
                </Grid>
              </Grid>
            </ListItem>
          }
        </Menu>
      </div>
    );
  }

  /**
   * Class Methods
   */

  // when the user starts to add a playlistthen cancels
  handleCancelNewPlayList = () => {
    this.setState({
      ...this.state,
      addingNewPlayList: false,
    })

  }
  
  handleClickButton = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCloseMenu = () => {
    this.setState({ anchorEl: null });
  };

  handleMenuItemClick = (event, index) => {

    if(!this.props 
      || !this.props.userInfo 
      || !this.props.userInfo.queues) {
      return null;
    }
    const { userInfo } = this.props;
    const { queues } = userInfo;
    
    // filter out the new queue
    const filteredQueues = queues.filter((q) => {
      return q !=='new';
    })
    let queueName = '';
    if (index==='new') {
      queueName = 'new'
    } else {
      queueName = filteredQueues[index];
    }

    this.props.onAddToQueue(queueName);
  };

  handleTextfieldChange = name => event => {
    this.setState({ [name]: event.target.value });
  };
  
  onClickCreatePlaylist = () => {
    this.setState({
      ...this.state,
      addingNewPlayList: true,
    })
  }
  onClickConfirmNewPlayList = async () => {
    // call the api to create a queue with the clip on it
    const { newPlaylistName } = this.state;
    const { onAddToQueue  } = this.props;

    onAddToQueue(newPlaylistName, 'create');
    this.setState({ 
      ...this.state,
      addingNewPlayList: false,
      newPlaylistName : '',
    });
  }
}

AddRemoveListButton.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  onAddToQueue: PropTypes.func.isRequired,
  onRemoveFromQueue: PropTypes.func.isRequired,
  userInfo: PropTypes.object.isRequired,
};

export default withStyles(styles)(AddRemoveListButton);
