import React from 'react';
// MATERIAL-UI
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
//LIB
import ValidEmail from '../../lib/validEmail';
// FETCH
import UserFetch from '../../fetch/userFetch';

const styles = (theme) => ({

});

class SimpleSignup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            tosAccepted: false,
            errorMessage: null
        };
    };

    render() {

        let invalidEmail = !ValidEmail.validEmail(this.state.email);
        let invalidPassword = this.state.password.length < 5;

        return (
            <Grid>
                <TextField
                    label="Email"
                    type="email"
                    margin="normal"
                    fullWidth
                    value={this.state.email}
                    onChange={(event)=>{this.setState({email: event.target.value})}}
                    onKeyPress={(ev) => {
                            if (ev.key === 'Enter') {
                              this.onButtonPressed();
                            }
                          }}
                />

                <TextField
                    label="Password"
                    type="password"
                    margin="normal"
                    fullWidth
                    value={this.state.password}
                    onChange={(event)=>{this.setState({password: event.target.value})}}
                    onKeyPress={(ev) => {
                                if (ev.key === 'Enter') {
                                  this.onButtonPressed();
                                }
                              }}
                />
                <div style={{textAlign: "center"}}>
                    <FormControlLabel control={<Checkbox
                                checked={this.state.tosAccepted}
                                onClick={() => {this.setState({tosAccepted: !this.state.tosAccepted})}}
                            />}
                                      label={<p>I accept the <a href='/terms-of-service' target='_blank'>terms of service</a> </p>}/>
                </div>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={this.onButtonPressed}
                    style={{marginTop: "20px"}}
                    fullWidth
                    disabled={invalidEmail || invalidPassword || !this.state.tosAccepted}
                >
                    Sign Up
                </Button>
                <Typography style={{textAlign: "center", marginBottom: "4px", color: "rgb(255, 120, 120)"}}>
                    {this.state.errorMessage}
                </Typography>
            </Grid>
        );
    }

    onButtonPressed = () => {
        this.setState({statusMessage: null, errorMessage: null}, () => {
            UserFetch.createUser(this.state.email, this.state.password).then(() => {
                UserFetch.userLogin(this.state.email, this.state.password).then(() => {
                    window.location = '/';
                });
            }).catch((error) => {
                this.setState({errorMessage: "Unable to create account. Email address already in use."})
            });
        });
    };
}

export default withStyles(styles)(SimpleSignup);
