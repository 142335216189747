import React from 'react';
// Material UI 
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
// COLOR
import grey from '@material-ui/core/colors/grey';

const styles = {
  logo: {
    flexBasis: 1,
  },
  tagline: {
    flexBasis: 1,
    color: grey[500],
  }
};

const ClipQueueBanner = (props) => {
  if(!props) {
    return null;
  }

  const { classes } = props;

  return ( 
    <Grid 
        container 
        spacing={8}
        direction="row"
        justify="center"
        alignItems="center"
    >
      <Grid item xs={12} align='center'>
          <Typography variant="h5" className={classes.logo}>
              <img alt="" 
                  align="top" 
                  src={process.env.PUBLIC_URL+'/beta.png'} 
                  height="22px" 
                  style={{paddingTop: '4px'}} 
              /> 
              <img alt="" align="center" src={process.env.PUBLIC_URL+'/clipcast-horizontal.png'} height="60px" ></img>
          </Typography>
          <Typography variant={'body1'} className={classes.tagline}>
              <i>Searchable Podcasts. Targeted Clips.</i>
          </Typography>
      </Grid>
    </Grid>
  );
}
 
export default withStyles(styles)(ClipQueueBanner);
