import React, { Fragment } from "react";
import PropTypes from "prop-types";
// MATERIAL-UI
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
// ICONS
import PlaylistAdd from "@material-ui/icons/PlaylistAdd";
import RemoveCircleOutline from "@material-ui/icons/RemoveCircleOutline";
// COLOR
import red from "@material-ui/core/colors/red";

const styles = theme => ({
  removeCircle: {
    color: red[800]
  }
});

const AddRemoveButton = props => {
  if (!props) {
    return null;
  }

  const { added, classes, disabled, onAddToQueue, onRemoveFromQueue } = props;

  return (
    <Fragment>
      {!added && (
        <Tooltip title="Add to Playlist" placement="top">
          <IconButton
            aria-label="Add to playlist"
            onClick={onAddToQueue}
            disabled={disabled}
          >
            <PlaylistAdd />
          </IconButton>
        </Tooltip>
      )}
      {added && (
        <Tooltip title="Remove from Playlist" placement="top">
          <IconButton
            aria-label="Remove from playlist"
            onClick={onRemoveFromQueue}
            disabled={disabled}
          >
            <RemoveCircleOutline className={classes.removeCircle} />
          </IconButton>
        </Tooltip>
      )}
    </Fragment>
  );
};

AddRemoveButton.propTypes = {
  added: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onAddToQueue: PropTypes.func.isRequired,
  onRemoveFromQueue: PropTypes.func.isRequired
};

export default withStyles(styles)(AddRemoveButton);
