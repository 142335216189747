import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
// FETCH
import UserFetch from '../../fetch/userFetch'
// LIB
import URLDecode from '../../lib/URLDecode'
// MATERIAL-UI
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    title: {
        fontSize: 14
    },
    pos: {
        marginBottom: 12
    }
});

class PortalComplete extends Component {

    state = {
        loading: true,
        error: null
    };


    render() {

        const waitingHeight = 60;
        const waitingTop = (window.innerHeight/2)-(waitingHeight/2);
        const waitingLeft = (window.innerWidth/2)-(waitingHeight/2);

        if (this.state.loading) {
            return (
                <div>
                    {this.state.loading && <CircularProgress
                        size={waitingHeight}
                        style={{margin: "auto", position: 'absolute', left: waitingLeft, top: waitingTop, zIndex: 99}}
                    />
                    }
                </div>
            )
        }

        if (this.state.error) {
            return (
                <Grid item xs={12} align='center'>
                    <Typography variant={'h3'}>
                        Houston, we have a problem...
                    </Typography>
                    <br/>
                    <Typography variant={'body1'}>
                        Unfortunately, we hit an issue with your signup. Please <a href="mailto:hello@clipcast.app">email us </a>
                        and include the email address of your account, we will get this sorted out for you. Sorry for the
                        inconvenience.
                    </Typography>
                </Grid>
            );
        }

        return (
            <Grid item xs={12} align='center'>
                <Typography component={'h4'}>
                    Thank You for Signing Up!
                </Typography>
                <br/>
                <Typography variant={'body1'}>
                    Your podcast episodes will now be made available automatically in Clipcast. You can manage your
                    account in our <a href="/portal/dashboard">portal</a>, including changing RSS feeds, viewing your billing usage, and viewing
                    analytics about your podcasts.
                </Typography>
            </Grid>
        );
    }

    componentDidMount() {
        let subscription_id = URLDecode.decodeObject(this.props.location.search).subscription_id;

        UserFetch.addUserSubscription(subscription_id).then((tags) => {
            this.setState({loading: false})
        }).catch((error) => {
            this.setState({loading: false, error: error})
        });
    };
}

export default withStyles(styles)(PortalComplete);