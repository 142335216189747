import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
// MATERIAL-UI
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles';
// ICONS
import Settings from '@material-ui/icons/SettingsOutlined';
// COLOR
import blueGrey from '@material-ui/core/colors/blueGrey';
// COMPONENTS
import DeletePlaylistDialog from './DeletePlaylistDialog';

const styles = theme => ({
  grow: {
    flexGrow: 1,
  },
  flex: {
    display: 'flex',
  },
  blueGrey: {
    color: blueGrey[600],
  }
});

class ClipQueueToolbar extends React.Component {
  state = {
    anchorEl: null,
    showDeleteDialog: false,
  };

  render() {
    const { props, state } = this;

    if(!props || !props.queue || !props.queue.queue_name) {
      return null;
    }

    const { classes, queue } = props;
    const { anchorEl, showDeleteDialog } = state;
    const { queue_name } = queue;

    let queueName = queue_name;
    let canDelete = true;
    if( queueName === 'new') {
      queueName = 'Listen later';
      canDelete = false;
    }

    const open = Boolean(anchorEl);

    return (
      <Fragment>
        <Grid
          container 
          spacing={8}
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item xs={6} >
            <Typography variant={'h6'} className={classes.blueGrey}>{queueName}</Typography>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.flex}>
              <div className={classes.grow} />
              {canDelete && 
                <div>
                  <IconButton className={classes.blueGrey} onClick={this.handleMenu}>
                    <Settings />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={open}
                    onClose={this.handleClose}
                  >
                    {/* <MenuItem onClick={this.handleRename}>Rename Playlist</MenuItem> */}
                    <MenuItem onClick={this.handleRequestDelete}>Delete Playlist</MenuItem>
                  </Menu>
                </div>
              }
            </div>
          </Grid>
        </Grid>
        <DeletePlaylistDialog 
          showDeleteDialog={showDeleteDialog} 
          onConfirmDelete={this.handleDelete} 
          onCancelDelete={this.handleCancelDelete}
          playlistName={queueName}
        />
      </Fragment>
    );
  }

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCancelDelete = () => {
    this.setState({
      ...this.state,
      showDeleteDialog: false,
    });
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  }

  handleDelete = () => {
    if(!this.props || !this.props.onDeleteQueue) {
      return null;
    }
    const { onDeleteQueue, queue } = this.props
    onDeleteQueue(queue.queue_name);
  };

  handleRename = () => {
    this.setState({ anchorEl: null });
  };

  handleRequestDelete = () => {
    this.setState({
      ...this.state,
      showDeleteDialog: true,
    })
  }
}

ClipQueueToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  onDeleteQueue: PropTypes.func.isRequired,
  onRenameQueue: PropTypes.func.isRequired, 
  queue: PropTypes.object.isRequired,
}

export default withStyles(styles)(ClipQueueToolbar);
