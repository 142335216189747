import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
      primary: {
          main: '#0288d1',
          light: '#5eb8ff',
          dark: '#005b9f'
      },
      secondary: {
          main: '#ff9800',
          light: '#ffe97d',
          dark: '#c88719'
      }
  },
  typography: {
    useNextVariants: true,
  },
});

export default theme;