import React from 'react';
import PropTypes from 'prop-types';
// MATERIAL-UI
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
// ICONS
import RemoveCircleOutlined from "@material-ui/icons/RemoveCircleOutline";
// COLOR
import red from '@material-ui/core/colors/red';

const styles = (theme) => ({
  card: {
    maxWidth: 345,
  },
  cardContent: {
    
  },
  cardLink: {
    textDecoration: 'none',
  },
  media: {
    width: 100,
    height: 100,
    margin: theme.spacing.unit,
  },
  removeCircle: {
    color: red[800],
  },
});

const Podcast = (props) =>  {
    if(!props || !props.podcast) {
      return null;
    }
  
    const { classes, podcast, onRemoved } = props;
    const { image, site_url, title } = podcast;

    return (
    <Card padding={16} className={ classes.card}>
        { image && <a
            rel="noopener noreferrer"
            target="_blank"
            href={site_url}
            className={classes.cardLink}>
                <CardMedia
                className={classes.media}
                image={image}
                title={title}
              />
        </a>}
        { !image &&
            <div>
                <br/>
                <Typography className={classes.cardText} component={'p'}>
                    New podcasts can take up to 20 minutes to add. Check back soon.
                </Typography>
            </div>
        }
      <CardContent className={classes.cardContent}>
          <Typography className={classes.cardText} variant={'h6'}>
              {title}
          </Typography>
      </CardContent>
        <CardActions disableActionSpacing>
            {onRemoved &&
            <Tooltip title="Remove Podcast" placement="top">
                <IconButton
                    aria-label="Share"
                    onClick={() => {onRemoved(podcast)}}
                >
                    <RemoveCircleOutlined className={classes.removeCircle} />
                </IconButton>
            </Tooltip>
            }
        </CardActions>
    </Card>
    );
}

Podcast.propTypes = {
  podcast: PropTypes.object.isRequired,
}

export default withStyles(styles)(Podcast);
