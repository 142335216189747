import React from 'react';

const Footer = () => {
    return (
        <footer
            style={{
                backgroundColor: "#0288d1", 
                textAlign: "center", 
                width: "100%", 
                position: "fixed", 
                bottom: "0",
                marginLeft: "-24px",
            }}
        >
            <p style={{fontSize: "12px", color: "#FFFFFF",  margin: "6px"}}>&copy; {new Date().getFullYear()} Smart Audio, LLC. Patent Pending. All Rights Reserved.</p>
        </footer>
    )
};

export default Footer;
