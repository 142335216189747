import { Component } from 'react';
// FETCH
import ClipsFetch from '../../fetch/clipsFetch'
// LIB
import URLDecode from '../../lib/URLDecode';

class EmailClipSig extends Component {

    render() { return null; }

    componentDidMount() {
        let params = URLDecode.decodeObject(this.props.location.search);
        let clipSig = window.location.pathname.substr(7); // minus the /email/

        ClipsFetch.postEmailClipPlayEvent(params.email, clipSig).then(() => {
            window.location = '/'+clipSig;
        }).catch((error) => {
            window.location = '/'+clipSig;
        });
    };
}

export default EmailClipSig;