import React from 'react';

import ClipsFetchs from '../../fetch/clipsFetch';
import UserFetch from '../../fetch/userFetch';
import ClipShareDialog from './clipShareDialog'

import Select from 'react-select';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import PlayArrow from '@material-ui/icons/PlayArrow';
import PauseSharp from '@material-ui/icons/PauseSharp';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

// of source, we need something special for iOS
const IOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

const customStyles = {
    menu: (provided, state) => ({
        ...provided,
        zIndex: "5"
    })
}

class ClipEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: null,
            selectedEpisode: null,
            episodes: [],
            playing: false,
            wavesurfer: null,
            region: null,
            playTime: 0,
            signature: null,
            shareDialogOpen: false,
            startMinutes: 0,
            startSeconds: 0,
            endMinutes: 2,
            endSeconds: 0,
            errorMessage: null,
            playbackMinutes: 0,
            playbackSeconds: "00"
        };
    }

    render() {
        let ButtonIcon = this.state.playing ? PauseSharp : PlayArrow;
        let options = this.state.episodes.map((episode) => {
            return {
                value: episode['key'],
                label: episode['podcast_name'] + ': ' + episode['entry_name'],
            }
        });

        const waitingHeight = 60;
        const waitingTop = (window.innerHeight/2)-(waitingHeight/2);
        const waitingLeft = (window.innerWidth/2)-(waitingHeight/2);

        return (
            <div>
                <Grid container spacing={24} alignItems="center">
                    <Grid item xs={3}>
                    </Grid>
                    <Grid item xs={6}>
                        <div style={{marginTop: "40px", marginBottom: "40px", textAlign: "center"}}>
                            <Typography component={'p'}>
                                <i>Select an episode to generate clips. It may take up to 60 seconds to load.</i>
                            </Typography>
                            <br/>
                            <Select
                                value={this.state.selectedOption}
                                onChange={this.onEpisodeSelected}
                                options={options}
                                styles={customStyles}
                            />
                        </div>
                    </Grid>
                </Grid>
                {this.state.loading && <CircularProgress
                    size={waitingHeight}
                    style={{margin: "auto", position: 'absolute', left: waitingLeft, top: waitingTop}}
                />
                }
                {this.state.selectedEpisode &&
                <div>
                    <Grid container spacing={24} alignItems="center">
                        <Grid item xs={2}>
                        </Grid>
                        <Grid item xs={8}>
                            <div id="waveform"></div>
                        </Grid>
                    </Grid>
                    {!this.state.loading &&
                    <Grid 
                        container 
                        spacing={24}
                        direction="row"
                        justify="center"
                        alignItems="center"
                    >
                        <Grid item xs={12}>
                            <Typography style={{marginBottom: "4px", textAlign:'center'}}>
                                <b>{this.state.playbackMinutes}:{this.state.playbackSeconds}</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{textAlign:'center'}}>
                            <div>
                                <br/>
                                <Typography component={'p'}>
                                    <u>Clip Start Time.</u>
                                </Typography>
                            </div>
                            <TextField
                                label="Minutes"
                                disabled={false}
                                value={this.state.startMinutes}
                                type="number"
                                margin="normal"
                                onChange={this.onTimeChanged('startMinutes')}
                                style={{margin: "5px", width: "100px"}}
                            />
                            <TextField
                                label="Seconds"
                                value={this.state.startSeconds}
                                type="number"
                                margin="normal"
                                onChange={this.onTimeChanged('startSeconds')}
                                style={{margin: "5px", width: "100px"}}
                            />
                            <div>
                                <br/>
                                <Typography component={'p'}>
                                    <u>Clip End Time.</u>
                                </Typography>
                            </div>
                            <TextField
                                label="Minutes"
                                disabled={false}
                                value={this.state.endMinutes}
                                type="number"
                                margin="normal"
                                onChange={this.onTimeChanged('endMinutes')}
                                style={{margin: "5px", width: "100px"}}
                            />
                            <TextField
                                label="Seconds"
                                value={this.state.endSeconds}
                                type="number"
                                margin="normal"
                                onChange={this.onTimeChanged('endSeconds')}
                                style={{margin: "5px", width: "100px"}}
                            />
                        </Grid>
                        <Grid item xs={12} style={{textAlign:'center', marginBottom:'10px'}}>
                            <Button 
                                variant="fab" 
                                color="primary" 
                                aria-label="Add" 
                                mini 
                                onClick={this.onPlayPause}
                            >
                                <ButtonIcon />
                            </Button>
                        </Grid>
                        <Grid item xs={12} style={{textAlign:'center', marginBottom:'10px'}}>
                            <Button 
                                variant="contained" 
                                color="default" 
                                onClick={this.generateClip}
                                style={{textAlign:'center'}}
                            >
                                Generate
                            </Button>
                        </Grid>
                    </Grid>
                    }
                </div>
                }
                <ClipShareDialog
                    onClose={this.onShareDialogClose}
                    open={this.state.shareDialogOpen}
                    signature={this.state.signature}
                    hideClipboardButton={IOS}
                />
                <Snackbar
                    open={this.state.errorMessage !== null}
                    onClose={this.handleErrorClose}
                    message={<span style={{textAlign: "center"}}>{this.state.errorMessage}</span>}
                    action={[
                        <IconButton
                          key="close"
                          aria-label="Close"
                          color="inherit"
                          onClick={this.handleErrorClose}
                        >
                          <CloseIcon />
                        </IconButton>,
                    ]}
                />
            </div>
        )
    }

    componentDidMount() {
        UserFetch.getUserInfo().then(user => {
            // if admin, we can get all episodes, which is null
            let podcsatIds = user.is_admin ? null : user.podcasts;

            ClipsFetchs.fetchEpisodes(podcsatIds).then((episodes) => {
                this.setState({episodes: episodes})
            }).catch((error) => {
                console.log('unable to fetch list')
            });
        });
    };

    onEpisodeSelected = (selectedEpisode) => {
        if (this.state.wavesurfer !== null) {this.state.wavesurfer.destroy()}

        var episode = this.state.episodes.find(function(e) {
            return e['key'] === selectedEpisode['value']
        });

        this.setState(
            {
                selectedOption: selectedEpisode,
                selectedEpisode: episode,
                loading: true,
            }, this.loadPlayer
        );
    };

    loadPlayer = () => {
        var wavesurfer = window.WaveSurfer.create({
            container: '#waveform',
            waveColor: '#ff9800',
            progressColor: '#005b9f',
            barWidth: 2,
            pixelRatio: 1,
            plugins: [
                window.WaveSurfer.regions.create({})
            ]
        });
        this.setState({wavesurfer: wavesurfer}, () => {
            this.state.wavesurfer.on('ready', () => {
                this.setState({loading: false}, this.addRegion);
            });
            this.state.wavesurfer.on('audioprocess', (time) => {
                let playbackTime = this.state.wavesurfer.getCurrentTime();
                let minutes = Math.floor(playbackTime / 60);
                let seconds = this.pad(Math.round(playbackTime - minutes * 60), 2);
                if (minutes !== this.state.playbackMinutes || seconds !== this.state.playbackSeconds) {
                    this.setState({
                        playbackMinutes: minutes,
                        playbackSeconds: seconds
                    })
                }
            });
            this.state.wavesurfer.on('region-updated', (region) => {
                let startMinutes = Math.floor(region.start / 60);
                let startSeconds = Math.round(region.start - startMinutes * 60);

                let endMinutes = Math.floor(region.end / 60);
                let endSeconds = Math.round(region.end - endMinutes * 60);

                this.setState({
                    startMinutes: startMinutes,
                    startSeconds: startSeconds,
                    endMinutes: endMinutes,
                    endSeconds: endSeconds
                })
            });
            this.state.wavesurfer.load(this.state.selectedEpisode['clipcast_url']);
            // eslint-disable-next-line
            this.state.wavesurfer.backend.length = 40;
            // eslint-disable-next-line
            this.state.wavesurfer.backend.explicitDuration = 40;
            this.state.wavesurfer.seekAndCenter(20 / wavesurfer.getDuration());
        });
    }

    onPlayPause = () => {
        this.setState({playing: !this.state.playing}, () => {
            if (this.state.playing) {
                this.state.region.play();
            } else {
                this.state.wavesurfer.pause();
            }
        });
    };

    generateClip = () => {
        let startTime = (this.state.startMinutes * 60) + this.state.startSeconds;
        let endTime = (this.state.endMinutes * 60) + this.state.endSeconds;
        ClipsFetchs.generateClip(this.state.selectedEpisode['key'], startTime, endTime).then((response) => {
            let signature = response['signature'];
            this.setState({
                signature: signature,
                shareDialogOpen: true
            })

        }).catch((error) => {
            console.log("unable to share")
        });
    };

    onShareDialogClose = () => {
        this.setState({
            shareDialogOpen: false
        })
    };

    onTimeChanged = name => event => {
        let value = parseInt(event.target.value);
        if (name.includes("Seconds")) {
            if (value < 0 || value > 59) {
                return
            }
        }
        if (name.includes("Minutes")) {
            if (value < 0) {
                return
            }
        }
        var newState = {...this.state};
        newState[name] = value;

        if ((newState.endMinutes * 60) + newState.endSeconds <  (newState.startMinutes * 60) + newState.startSeconds) {
            newState.endMinutes = newState.startMinutes;
            newState.endSeconds = newState.startSeconds;
        }

        this.setState(newState, this.setClip);
    };

    setClip = () => {
        this.state.region.remove();
        this.addRegion();
    };

    addRegion = () => {
        var region = this.state.wavesurfer.addRegion({
            start: (this.state.startMinutes * 60) + this.state.startSeconds,
            end: (this.state.endMinutes * 60) + this.state.endSeconds,
            loop: true,
            drag: true,
            resize: true,
            buttonsEnabled: true
        });
        this.setState({region: region})
    };

    handleErrorClose = () => {
        this.setState({ errorMessage: null });
    };

    pad = (num, size) => {
        var s = String(num);
        while (s.length < (size || 2)) {s = "0" + s;}
        return s;
    };
}

export default ClipEditor;