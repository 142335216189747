import React from 'react';
// MATERIAL-UI
import Typography from '@material-ui/core/Typography';
// COMPONENTS
import PodcastsList from './PodcastsList';
// ICONS
import Feedback from "@material-ui/icons/Feedback";

const About = () => {
    return (
        <div>
            <div>
                <Typography variant={'h5'} >
                    About Clipcast
                </Typography>

                <Typography variant={'body1'}>
                    Clipcast is a search engine for podcasts. Search for a word or phrase and get a list of clips from
                    podcasts that include your topic.
                </Typography>
                <br/>

                <Typography variant={'h5'}>
                    How It Works
                </Typography>

                <Typography variant={'body1'}>
                    Enter any name or phrase that you want to listen to, we will pull the relevant podcasts
                    directly from the podcasters host and create relevant audio clips for you to listen to. Each clip
                    is usually a few minutes in length and is a highlight of your topic.
                </Typography>
                <br/>
                <Typography variant={'body1'}>
                    If you want to listen to the entire podcast, you can jump to it directly from the clip, or you can share
                    clips directly from our site. You can also add clips to playlists to listen to them back-to-back about
                    one or many topics.
                </Typography>
                <br/>
                <Typography variant={'body1'}>
                    Podcasters on our site get access to many other features, including analytics about your podcast and
                    how people are searching it, a clip generator tool to make your own clips, and the ability to search
                    all your episodes. It's like a digital library for your podcast.
                </Typography>

                <br/>
                <Typography variant={'h5'} >
                    Can't find something?
                </Typography>

                <Typography variant={'body1'}>
                    We are constantly improving our algorithms and machine learning models to make podcasts searchable,
                    but it isn't always perfect. If you can't find an exact name, try searching for only the first or
                    last name. If it is an uncommon spelling of a name, try a slightly different variation. Or sometimes
                    spelling out a name phonetically will help.
                </Typography>
                <br/>
                <Typography variant={'body1'}>
                    If you spot errors, you can supply feedback directly from our homepage by clicking the
                    <Feedback style={{color: "#888"}}/> button next to the search bar.
                </Typography>

                <br/>
                <Typography variant={'h5'} >
                    Podcasts
                </Typography>

                <Typography variant={'body1'}>
                    We are currently focusing on fantasy sports podcasts, but our system can make any podcasts searchable.
                    If you would like to add your podcast, you can create an account by clicking Login at the top right,
                    and then Add Your Pod from the navigation window.
                </Typography>
                <br/>
                <Typography variant={'body1'}>
                    Our current list of supported podcasts is below.
                    Don't see your favorite podcast here? Have feedback for us? <a href="mailto:hello@clipcast.app">Let us know!</a>
                </Typography>
            </div>
            <br />
            <div>
                <PodcastsList/>
            </div>
        </div>
    )
};

export default About;