// CONFIG
import AppConfig from '../appConfig';
// SENTRY
import * as Sentry from '@sentry/browser';

class AbstractFetch {
    static fetch = (url, options, retry=false) => {
        if (!options.headers) {
            options.headers = {}
        }
        options.headers['X-CLIENT-TYPE'] = 'web';

        return fetch(url, options).then(AbstractFetch.handleErrors);
    };

    static handleErrors(response) {

        if (response.ok) {
            return response;
        }

        if (AppConfig.enableShowErrorLog) {
            console.log("handleErrors with error:");
            console.log(JSON.stringify(response.json()));
        }

        // log to sentry
        if (response.status >= 500) {
            Sentry.captureMessage("Error fetching " + response.url + " got back " + response.status);
        }

        throw Error(response.status);

    }
}

export default AbstractFetch;