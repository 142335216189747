import React, { Component } from "react";

// MATERIAL-UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
// FETCH
import UserFetch from '../../fetch/userFetch';
// LIB
import format from 'date-fns/format';

const styles = theme => ({
    root: {
        margin: theme.spacing.unit*6
    },
    line: {
        backgroundColor: '#222'
    }
});

class ManageSubscription extends Component {

    state = {
        subscription: null,
        unbilled: null,
        loading: true

    };

    render() {

        const { classes } = this.props;

        const waitingHeight = 60;
        const waitingTop = (window.innerHeight/2)-(waitingHeight/2);
        const waitingLeft = (window.innerWidth/2)-(waitingHeight/2);


        return (
            <div className={classes.root}>
                {this.state.loading && <CircularProgress
                    size={waitingHeight}
                    style={{margin: "auto", position: 'absolute', left: waitingLeft, top: waitingTop, zIndex: 99}}
                />
                }

                <Grid
                    container
                    spacing={24}
                    direction="column"
                    justify="center"
                    alignItems="center"
                >
                    {this.state.subscription && this.state.unbilled &&
                        <div>
                            <Typography variant={'body1'}>
                                <b>Current Term</b>: {format(this.state.subscription.current_term_start, 'MM/DD/YYYY')} to {format(this.state.subscription.current_term_end, 'MM/DD/YYYY')}
                            </Typography>
                            <hr/>
                            <Typography variant={'body1'}>
                                <b>Current Estimated Amount Due</b>: {this.currencyFormatter(this.state.unbilled.amount_due)}
                            </Typography>
                            <Typography variant={'body1'}>
                                <i>Details</i>
                            </Typography>
                            <br/>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Amount</TableCell>
                                        <TableCell>Discount</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.unbilled.line_items.map(line_item => (
                                        <TableRow key={line_item.date}>
                                            <TableCell>{format(line_item.date, 'MM/DD/YYYY')}</TableCell>
                                            <TableCell>{this.currencyFormatter(line_item.amount)}</TableCell>
                                            <TableCell>{this.currencyFormatter(line_item.discount)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    }
                    <br/>
                    <Typography variant={'body1'}>
                        Manage your payment information through our gateway
                    </Typography>
                    <br/>
                    <Button data-cb-type="portal" data-cb-plan-id="clipcast-podcaster" variant="contained" color="primary">
                        Payment Gateway
                    </Button>
                </Grid>
            </div>
        )
    }

    componentDidMount() {
        UserFetch.getSubscription().then((subscription) => {
            this.setState({subscription: subscription, loading: false});
        }).catch((error) => {

        });
        UserFetch.getUnbilled().then((unbilled) => {
            this.setState({unbilled: unbilled, loading: false});
        }).catch((error) => {

        });

        // this is required to force the Chargbee script to re-attach to the button
        // https://www.chargebee.com/checkout-portal-docs/drop-in-tutorial.html#implementation
        window.Chargebee.registerAgain();
    };

    formatFloat = (number) => {
        return number
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }

    currencyFormatter = (amt) => {
        if (amt !== 0 && !amt) { return "";}
        return "\x24" + this.formatFloat(amt);
    }
}

export default withStyles(styles)(ManageSubscription);