import React from "react";
import PropTypes from "prop-types";
// MATERIAL-UI
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
// ICONS
import CloseIcon from "@material-ui/icons/Close";
import Feedback from "@material-ui/icons/Feedback";
// FETCH
import ClipsFetchs from "../../fetch/clipsFetch";
// COMPONENTS
import ClipShareDialog from "../common/clipShareDialog";
import ClipFilters from "../common/clipFilters";
import ClipCard from "./ClipCard";
import FeedbackDialog from "../common/feedbackDialog";
// LIB
import URLDecode from "../../lib/URLDecode";
// ROUTER
import { Link } from "react-router-dom";

// of source, we need something special for iOS
const IOS =
  typeof navigator !== "undefined" &&
  /iPad|iPhone|iPod/.test(navigator.userAgent) &&
  !window.MSStream;

const styles = theme => ({
  root: {
    display: "flex"
  },
  link: {
    textDecoration: "none"
  },
  mainButton: {
    marginLeft: theme.spacing.unit*2,
    marginRight: theme.spacing.unit*2,
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    minWidth: 140
  },
  whatIsThisButton: {
    color: "rgb(120, 120, 120)"
  },
  badgeBar: {
    marginTop: "-12px",
    marginBottom: "-12px",
    marginLeft: "16px"
  }
});

/**
 * ClipSearch - container component that is responsible for fetching all the data
 * for search and rendering clips.
 */
class ClipsSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      keywords: "",
      clips: [],
      loading: false,
      snackMessage: null,
      sharedSignature: null,
      shareDialogOpen: false,
      filtersOpen: false,
      searchedTags: null,
      tagMessage: null,
      userSearchMessage: null,
      searchUserPodcasts: false,
      preferredPodcastId: null,
      exclusivePodcastId: null,
      searchLimit: 10,
      searchOffset: 0,
      lastHits: null,
      mostPopular: false,
      feedbackDialogOpen: false,
      topOfClipsRef: React.createRef(), // used to scroll to the top of the clips on search
      scrolledOnce: true // used to only scroll down to the first page of results
    };
  }

  render() {
    const { clips } = this.state;
    const { classes, getUserInfo, userInfo } = this.props;

    const waitingHeight = 60;
    const waitingTop = window.innerHeight / 2 - waitingHeight / 2 + window.pageYOffset;
    const waitingLeft = window.innerWidth / 2 - waitingHeight / 2;

    let searchWidth = window.innerWidth < 500 ? "75%" : "45%";
    let searchLabel = window.innerWidth < 500 ? "keywords" : "topic, player, team, etc.";

    return (
      <div className={classes.root}>
        {this.state.loading && (
          <CircularProgress
            size={waitingHeight}
            style={{
              margin: "auto",
              position: "absolute",
              left: waitingLeft,
              top: waitingTop,
              zIndex: 99
            }}
          />
        )}
        <Grid
          container
          spacing={24}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12} align="center">
            <Typography align="center" variant="h5" component="p">
              <img
                alt=""
                align="center"
                src={process.env.PUBLIC_URL + "/clipcast-horizontal.png"}
                height="60px"
              />{" "}
            </Typography>
            <Typography
              align="center"
              style={{ marginBottom: "4px", color: "rgb(120, 120, 120)" }}
            >
              <i>Searchable Podcasts. Targeted Clips.</i>
            </Typography>
          </Grid>
          <Grid item xs={12} align="center" className={classes.badgeBar}>
            <a href="https://apps.apple.com/app/id1469480446" target="_blank" rel="noopener noreferrer">
              <img
                  alt=""
                  align="center"
                  src={process.env.PUBLIC_URL + "/app_store_download.svg"}
                  height="42px"
              />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.reactnativeclipcast" target="_blank" rel="noopener noreferrer">
              <img
                  alt=""
                  align="center"
                  src={process.env.PUBLIC_URL + "/google_play_download.png"}
                  height="60px"
              />
            </a>
          </Grid>
          <Grid item xs={12} align="center">
            <TextField
              label={searchLabel}
              value={this.state.keywords}
              margin="normal"
              style={{ width: searchWidth }}
              onChange={this.handleKeywordChange}
              onKeyPress={ev => {
                if (ev.key === "Enter") {
                  this.props.history.push(this.buildURL());
                }
              }}
            />
            <Tooltip title="Filters" placement="top">
              <IconButton
                aria-label="Filters"
                onClick={this.onFiltersOpen}
                style={{ marginLeft: "auto", verticalAlign: "bottom", padding: "6px" }}
              >
                <img
                  alt=""
                  align="center"
                  src={process.env.PUBLIC_URL + "/filter.svg"}
                  height="30px"
                  style={{ marginLeft: "auto" }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Provide Feedback" placement="top">
              <IconButton
                  key="feedback"
                  aria-label="Feedback"
                  color="inherit"
                  onClick={this.handleFeedbackClick}
                  style={{ marginLeft: "auto", verticalAlign: "bottom", padding: "6px" }}

              >
                <Feedback style={{color: "#888"}}/>
            </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12} align="center">
            <Link to={this.buildURL()} className={classes.link}>
              <Button variant="contained" color="default" className={classes.mainButton}>
                Search
              </Button>
            </Link>
            <Link to={'/popular'} className={classes.link}>
              <Button variant="contained" color="default" className={classes.mainButton}>
                Most Popular
              </Button>
            </Link>
          </Grid>
          <Grid item xs={12} align="center">
            <Link to="/about" className={classes.link}>
              <Button className={classes.whatIsThisButton}>What is this thing?</Button>
            </Link>
          </Grid>
          <Grid item xs={12} align="center">
            {this.state.tagMessage && (
              <Typography
                style={{ marginBottom: "4px", color: "rgb(120, 120, 120)" }}
              >
                <i>{this.state.tagMessage}</i>
              </Typography>
            )}
            {this.state.userSearchMessage &&
              <Typography
                  style={{ marginBottom: "4px", color: "rgb(120, 120, 120)" }}
              >
                <i>{this.state.userSearchMessage}</i>
              </Typography>
            }
            {this.state.mostPopular &&
              <Typography
                  style={{ marginBottom: "4px" }}
              >
                <h3>Most Popular Clips</h3>
              </Typography>
              }
          </Grid>
          <Grid item xs={12} align="center">
            {clips && (
                <div ref={this.state.topOfClipsRef}></div>
            )}
            {clips && (
              <Grid container spacing={24} justify="space-around">
                {clips.map(clip => (
                  <Grid
                    item
                    key={clip.signature}
                    xs={12}
                    sm={6}
                    lg={4}
                    align="center"
                  >
                    <ClipCard
                      key={clip.signature}
                      clip={clip}
                      onShareClip={this.onShareClip}
                      onAddMinute={this.onAddMinute}
                      setSnackMessage={this.setSnackMessage}
                      userInfo={userInfo}
                      getUserInfo={getUserInfo}
                    />
                  </Grid>
                ))}
                {this.state.lastHits === 0 && clips.length === 0 &&
                  <Typography
                      style={{ marginBottom: "4px", color: "rgb(120, 120, 120)" }}
                  >
                    <b>No search results. Please try different keywords.</b>
                  </Typography>
                }
              </Grid>
            )}
          </Grid>
        </Grid>
        <ClipShareDialog
          onClose={this.onShareDialogClose}
          open={this.state.shareDialogOpen}
          signature={this.state.signature}
          hideClipboardButton={IOS}
        />
        <FeedbackDialog
            onClose={this.handleFeedbackClick}
            open={this.state.feedbackDialogOpen}
            setSnackMessage={this.setSnackMessage}
        />

        <ClipFilters
          onClose={this.onFiltersClose}
          open={this.state.filtersOpen}
          tags={this.state.searchedTags}
          searchUserPodcasts={this.state.searchUserPodcasts}
          showAllSearchOptions={true}
          userHasPodcasts={userInfo.podcasts && userInfo.podcasts.length}
          setCriteria={this.setCriteria}
          filterLabel="Only search podcasts with the tags..."
        />

        <Snackbar
          open={this.state.snackMessage !== null}
          onClose={this.handleErrorClose}
          message={
            <span style={{ textAlign: "center" }}>
              {this.state.snackMessage}
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleErrorClose}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />
      </div>
    );
  }

  handleFeedbackClick = () => {
    this.setState({feedbackDialogOpen: !this.state.feedbackDialogOpen})
  }

  handleKeywordChange = event => {
    this.setState({ keywords: event.target.value });
  };

  setCriteria = (tags, searchUserPodcasts) => {
    this.setState({ searchedTags: tags, searchUserPodcasts: searchUserPodcasts });
  };
  fetchClips = () => {
    let tagMessage =
      this.state.searchedTags && this.state.searchedTags.length > 0
        ? "Searched with the following tags:" +
          this.state.searchedTags.join(", ")
        : null;

    let userSearchMessage =
        this.state.searchUserPodcasts
            ? "Searching only user podcasts" : null;

    this.setState(
      {
        loading: true,
        snackMessage: null,
        tagMessage: tagMessage,
        userSearchMessage: userSearchMessage,
        mostPopular: false
      },
      () => {
        ClipsFetchs.searchClips(
            this.state.keywords.trim(),
            this.state.searchedTags,
            this.state.searchUserPodcasts,
            this.state.preferredPodcastId,
            this.state.exclusivePodcastId,
            this.state.searchLimit,
            this.state.searchOffset
        )
          .then(clips => {
            if (
              this.state.keywords !== clips.keywords ||
              JSON.stringify(this.state.searchedTags) !==  JSON.stringify(clips.tags) ||
              this.state.searchUserPodcasts !==  clips.search_user_podcasts
            ) {
              return;
            }
            this.setState({
              clips: this.state.clips.concat(clips.clips),
              loading: false,
              lastHits: clips.hits,
              searchOffset: this.state.searchOffset+this.state.searchLimit // this is a hack, but an effective one
            }, this.scrollToTopOfClips);
          })
          .catch(error => {
            this.setState({
              snackMessage: "Unable to search. Please try again.",
              loading: false
            });
          });
      }
    );
  };

  fetchClip = signature => {
    this.setState({ loading: true, clips: [], snackMessage: null, mostPopular: false, scrolledOnce: false }, () => {
      ClipsFetchs.fetchClip(signature)
        .then(clip => {
          this.setState({
            clips: [clip["clip"]],
            loading: false,
            signature: signature
          }, this.scrollToTopOfClips);
        })
        .catch(error => {
          this.setState({
            snackMessage: "Unable to load clip.",
            loading: false
          });
        });
    });
  };

  fetchPopularClips = () => {
    this.setState({ loading: true, clips: [], snackMessage: null, scrolledOnce: false }, () => {
      console.log(this.state.loading);
      ClipsFetchs.fetchPopularClips()
          .then(clips => {
            this.setState({
              clips: clips["clips"],
              lastHits: 0, // setting this will make it so we don't try to page
              loading: false,
              mostPopular: true
            }, this.scrollToTopOfClips);
          })
          .catch(error => {
            this.setState({
              snackMessage: "Unable to load popular clips.",
              loading: false
            });
          });
    });
  }

  componentDidMount() {
    if (window.location.pathname === "/popular") {
      this.fetchPopularClips();
    } else if (window.location.pathname !== "/") {
      let signature = window.location.pathname.substr(1);
      this.fetchClip(signature);
    } else {
      this.updateClips();
    }
    document.addEventListener('scroll', this.trackScrolling);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.trackScrolling);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      this.updateClips();
    }
  }

  trackScrolling = (e) => {
    const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
    const windowBottom = windowHeight + window.pageYOffset;
    if (windowBottom >= docHeight-10 && this.state.lastHits > 0 && !this.state.loading) {
      this.fetchClips();
    }
  }

  scrollToTopOfClips = (ref) => {
    if (this.state.scrolledOnce) {return;}

    window.scrollTo({
      top: this.state.topOfClipsRef.current.offsetTop-128, // 128 is the height of the toolbar
      behavior: 'smooth'
    });

    this.setState({scrolledOnce: true})
  };

  updateClips = () => {
    let params = URLDecode.decodeObject(this.props.location.search);
    if (params.keywords && params.keywords !== "") {
      let tags =
        !params.tag || Array.isArray(params.tag)
          ? params.tag === undefined
            ? null
            : params.tag
          : [params.tag];
      let searchUserPodcasts = params.searchUserPodcasts === "true";
      let preferredPodcastId = params.preferredPodcastId ? params.preferredPodcastId : params.preferredpodcastid;
      let exclusivePodcastId = params.exclusivePodcastId ? params.exclusivePodcastId : params.exclusivePodcastId;
      this.setState(
        {
          loading: false,
          clips: [],
          keywords: params.keywords,
          searchedTags: tags,
          searchUserPodcasts: searchUserPodcasts,
          preferredPodcastId: preferredPodcastId,
          exclusivePodcastId: exclusivePodcastId,
          searchLimit: 10,
          searchOffset: 0,
          lastHits: null,
          scrolledOnce: false
        },
        this.fetchClips
      );
    } else {
      this.setState({
        loading: false,
        keywords: "",
        clips: [],
        tagMessage: null,
        userSearchMessage: null,
        searchLimit: 10,
        searchOffset: 0,
        lastHits: null,
        scrolledOnce: false
      });
    }
  };

  buildURL = () => {
    var url = "/?keywords=" + this.state.keywords;
    if (this.state.searchedTags) {
      this.state.searchedTags.forEach(tag => {
        url += "&tag=" + tag;
      });
    }
    if (this.state.searchUserPodcasts) {
      url+="&searchUserPodcasts=" + this.state.searchUserPodcasts;
    }
    return url;
  };

  onShareClip = clip => {
    ClipsFetchs.saveClip(clip)
      .then(response => {
        let signature = response["signature"];
        this.setState({
          signature: signature,
          shareDialogOpen: true
        });
      })
      .catch(error => {
        console.log("unable to share");
      });
  };

  onAddMinute = (clip, timeToAdd) => {
    let newClips = this.state.clips.map(c => { return {...c} });
    let clipToEdit = newClips.find(c => c.signature === clip.signature);
    clipToEdit.end_time += timeToAdd;
    this.setState({clips: newClips});
  };

  onShareDialogClose = () => {
    this.setState({
      shareDialogOpen: false
    });
  };

  onFiltersOpen = () => {
    this.setState({
      filtersOpen: true
    });
  };

  onFiltersClose = () => {
    this.setState({
      filtersOpen: false
    });
  };

  handleErrorClose = () => {
    this.setState({ snackMessage: null });
  };

  setSnackMessage = msg => {
    this.setState({ snackMessage: msg });
  };
}

ClipsSearch.propTypes = {
  getUserInfo: PropTypes.func.isRequired,
  userInfo: PropTypes.shape({
    email: PropTypes.string,
    loggedIn: PropTypes.bool,
    queues: PropTypes.array
  }).isRequired,
};

export default withStyles(styles)(ClipsSearch);
