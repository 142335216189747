import React, { Component } from "react";

// MATERIAL-UI
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
// ICONS
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
//Components
import RSSFeed from '../common/RSSFeed';

const styles = theme => ({
    fab: {
        margin: theme.spacing.unit,
    }
});

class StepTwo extends Component {

    state = {
        codePanelOpen: false,
        podcastCode: ""
    };

    render() {
        const { classes } = this.props;

        return (
            <div>
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                >
                    <Typography variant={'body1'}>
                        Enter your podcasts below. Accurate tags will result in better search performance.
                    </Typography>
                    {this.props.feeds.map((feed, index) => (
                        <RSSFeed
                            key={index}
                            index={index}
                            feed={feed}
                            onFeedChanged={this.props.handleFeedChange}
                            disableSelection={this.props.codeUsed}
                        />
                    ))}
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                    >
                        <Fab color="primary" aria-label="Remove" size="small" className={classes.fab} onClick={this.props.onRemove}>
                            <RemoveIcon />
                        </Fab>
                        <Fab color="primary" aria-label="Add" size="small" className={classes.fab} onClick={this.props.onAdd}>
                            <AddIcon />
                        </Fab>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center">
                        <Typography component={'p'}>
                            Have a podcast code from a Clipcast associate?
                        </Typography>
                        <Button onClick={() => {this.setState({codePanelOpen: true})}}>Click Here</Button>
                    </Grid>
                    {this.state.codePanelOpen && <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center">
                        <TextField
                            label="Podcast Code"
                            value={this.state.podcastCode}
                            margin="normal"
                            onChange={(event) => {this.setState({podcastCode: event.target.value})}}
                        />
                        <Button onClick={() => {this.props.onSubmitCode(this.state.podcastCode)}}>Submit</Button>
                    </Grid>}
                </Grid>
            </div>
        )
    }

}


export default withStyles(styles)(StepTwo);