import React from 'react';
import { withStyles } from "@material-ui/core/styles";

import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import UserFetch from "../../fetch/userFetch";
import AppStore from "../../fetch/appStore"

const styles = theme => ({

});

class FeedbackDialog extends React.Component {

    state = {
        feedback: ""
    };

    render() {

        return (
            <div>
                <Dialog fullWidth maxWidth="sm" onClose={this.props.onClose} aria-labelledby="provide-feedback" open={this.props.open}>
                    <DialogContent>
                        <TextField
                            fullWidth
                            multiline
                            id="standard-textarea"
                            label="Provide Feedback"
                            placeholder="Tell us about our app or a problem you encountered"
                            margin="normal"
                            value={this.state.feedback}
                            onChange={(event)=>{this.setState({feedback: event.target.value})}}
                        />

                        <Button variant="contained" color="primary" onClick={this.onSubmitClicked}>
                            Submit
                        </Button>

                    </DialogContent>
                </Dialog>
            </div>
        )
    }

    onSubmitClicked = () => {
        let userInfo = AppStore.fetchUserInfo();
        let user = userInfo.email ? userInfo.email : "anonymous";

        UserFetch.postUserFeedback("User " + user + " posted: " + this.state.feedback)
            .then(response => {
                this.props.setSnackMessage("Thank you for submitting feedback.")
                this.props.onClose();
            })
            .catch(error => {
                this.props.setSnackMessage("Error submitting feedback. Please try again later.")
                this.props.onClose();
            });
    }
}

export default withStyles(styles)(FeedbackDialog);