import React, { Component } from "react";

// MATERIAL-UI
import { withStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
// ICONS
import { QuestionAnswer, ExpandMore, SpeakerNotes } from '@material-ui/icons';

const styles = theme => ({
    card: {
        margin: theme.spacing.unit * 2
    }
});

class StepOne extends Component {

    render() {
        const { classes } = this.props;

        return (
            <div>
                <Typography variant={'h5'}>
                    Clipcast is an entirely new way for people to experience your podcast.
                </Typography>
                <br/>
                <Typography component={'p'}>
                    Sign up your podcast now to reach new customers, increase your downloads, and get insights into how
                    people are finding and listening to your content.
                </Typography>
                <br/>
                <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                        <QuestionAnswer/><Typography style={{marginLeft: "10px"}}> Frequenctly Asked Questions</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <div>
                            <Typography component={'p'}>
                                <b>Why join Clipcast?</b> We provide a new way for listeners to find your content. Your podcasts will be
                                searchable and accessible to all Clipcast users. You will get analytics and insights into how
                                people find your podcast and what keywords are most popular. Plus, most times someone listens to
                                your episodes on Clipcast, they will count as a podcast download.
                            </Typography>

                            <br/>

                            <Typography component={'p'}>
                                <b>How does Clipcast work?</b> Once your pocast is added to our system, we will
                                automatically add new episodes as they are published. When a user searches Clipcast for
                                a particular keyword, we search through all episodes on our system and generate a small
                                section of your podcast around that topic, we call these Clips. One episode may return
                                more than one Clip per episode, which is totally fine. Users can then listen to the clips
                                or add them to their playlist. Each clip plays the audio directly from your podcast feed,
                                we don't host your files, so that means it counts as a download to your podcast.
                            </Typography>

                            <br/>

                            <Typography component={'p'}>
                                <b>What types of analytics do I see?</b> We collect statistics about how people are
                                finding your episodes, which keywords were searched when they clicked play, and what
                                days and times are you getting the most traffic.

                            </Typography>

                            <br/>

                            <Typography component={'p'}>
                                <b>What types of tools do I get?</b> You will have access to our clip generator, which
                                allows you to create clips of your podcasts without requiring search terms. Got a great
                                guest on your show? Or want to push out your opinion on some topic? You can make clips,
                                share them anywhere, and people can listen to them or add them to their playlists. You
                                will also have the ability to search only your own podcasts, great for researching your
                                next show or simply trying to find relevant sections of your old episodes.

                            </Typography>

                            <br/>

                            <Typography component={'p'}>
                                <b>Who uses Clipcast?</b> You can visit our <a href="/about">about</a> page to see the ever growing
                                list of podcasters who are reaping the benefits of Clipcast.
                            </Typography>

                            <br/>

                            <Typography component={'p'}>
                                <b>What do I need to provide?</b> With Clipcasts technology, you don't need to provide us anything
                                but your RSS URL to start enjoying all the benefits.
                            </Typography>

                            <br/>

                            <Typography component={'p'}>
                                <b>After I signup, do I need to tag my pods?</b> Nope. Clipcast uses your RSS feed and your
                                episodes will automatically be searchable on our site within about an hour. You don't have to
                                do anything extra.
                            </Typography>

                            <br/>

                            <Typography component={'p'}>
                                <b>How will I be billed?</b> You pay $0 up-front or monthly, there are no hidden costs
                                or fees. For each episode you publish, you are charged a flat fee for that episode. We
                                then bill you once a month only for your usage.
                            </Typography>

                            <br/>

                            <Typography component={'p'}>
                                <b>If I don't publish any episodes one month, will my bill be $0?</b> Yup! This way, you
                                don't have to worry about pausing or canceling your account. You only pay when you use
                                the system.
                            </Typography>

                            <br/>
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <br/>
                <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                        <SpeakerNotes/><Typography style={{marginLeft: "10px"}}> Testimonials</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container alignItems="center">
                            <Grid item xs={10} >
                                <Typography component={'p'}>
                                    <i>“Clipcast may be the most underrated tool in the fantasy community right now. You can just type in whatever you’re looking for and listen to small segments on that subject from your favorite podcasts. Time is money!”.</i>
                                </Typography>
                                <br/>
                                <Typography component={'p'}>
                                    - J Wackerly
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" style={{textAlign: "center"}}>
                            <Grid item xs={10} >
                                <Typography component={'p'}>
                                    <i>This thing is legit... Find out what your favorite podcast analysts or host says about a specific player."</i>
                                </Typography>
                                <br/>
                                <Typography component={'p'}>
                                    - GOAT District: Fantasy Football Podcast
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" style={{textAlign: "center"}}>
                            <Grid item xs={10} >
                                <Typography component={'p'}>
                                    <i>Clipcast is a f*%&ing brilliant idea man. I regularly search my players names on twitter. This is next level."</i>
                                </Typography>
                                <br/>
                                <Typography component={'p'}>
                                    - @DoctaDynasty
                                </Typography>
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <br/>
                <Grid container alignItems="center">
                    <Grid item xs={12} >
                        <Card className={classes.card}>
                            <CardContent>
                                <Typography gutterBottom>
                                    Podcaster Plan
                                </Typography>
                                <Typography variant="h5">
                                    $3/Episode
                                </Typography>
                                <Typography color="textSecondary">
                                    Includes the following
                                </Typography>

                                <List>
                                    <ListItem>
                                        <ListItemText
                                            primary="Episodes are made searchable on our site when they are published"
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primary="Access to analytics about how your podcast is performing on Clipcast"
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primary="Generate custom clips to share with your listeners"
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primary="Ability to search only your own podcasts"
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primary="Associate as many RSS feeds as you like to your account"
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primary="Pay only for episodes you publish, no hidden costs or fees"
                                        />
                                    </ListItem>
                                </List>

                                <Typography color="textSecondary">
                                    * Billed Monthly. Cancel any time.
                                </Typography>

                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        );
    }
}


export default withStyles(styles)(StepOne);