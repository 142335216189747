import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
// MATERIAL-UI
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
//Components
import StepOne from './StepOne'
import StepTwo from './StepTwo'
import StepThree from './StepThree'
// FETCH
import UserFetch from '../../fetch/userFetch'

const styles = theme => ({
    title: {
        fontSize: 14
    },
    pos: {
        marginBottom: 12
    }
});

function getSteps() {
    return ['Get Started', 'Enter your RSS URL', 'Enter payment information', 'Complete'];
}

class PortalSignup extends Component {


    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
            completed: {},
            feeds: [this.getBlankFeed()],
            codeUsed: false
        };
    }


    render() {
        const { activeStep } = this.state;
        const steps = getSteps();

        return (
            <div>
                <Stepper nonLinear activeStep={activeStep}>
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepButton completed={this.state.completed[index]}>
                                {label}
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>
                <br/>
                {this.state.activeStep === 0 && <StepOne/>}
                {this.state.activeStep === 1 && <StepTwo
                    onAdd={this.onAddFeed}
                    onRemove={this.onRemoveFeed}
                    feeds={this.state.feeds}
                    handleFeedChange={this.handleFeedChange}
                    onSubmitCode={this.onSubmitCode}
                    codeUsed={this.state.codeUsed}
                />}
                {this.state.activeStep === 2 && <StepThree
                    onPaymentClicked={this.onPaymentClicked}
                />}
                <br/>

                <Grid container alignItems="center" style={{textAlign: "center", marginBottom: "20px"}}>
                    <Grid item xs={6} >
                        {(this.state.activeStep === 1 || this.state.activeStep === 2) &&
                        <Button variant="contained" color="primary" onClick={this.onPrevious}>
                            Previous
                        </Button>}
                    </Grid>

                    <Grid item xs={6} >
                        {this.state.activeStep < 2 &&
                        <Button variant="contained" color="primary" onClick={this.onNext} disabled={this.nextDisabled()}>
                            Next
                        </Button>}
                    </Grid>
                </Grid>
            </div>
        );
    }

    componentDidMount() {
        UserFetch.getUserInfo().then((userInfo) => {
            if (userInfo.podcasts) {
                window.location = '/portal/dashboard';
            }
        }).catch((error) => {

        });
    };

    // Top level callbacks
    // -----------------------------------------
    nextDisabled = () => {
        if (this.state.activeStep === 1) {
            for (var i = 0; i < this.state.feeds.length; i++) {
                if (!this.state.feeds[i].urlValid) {
                    return true;
                }
            }
        }

        return false;
    }

    onNext = () => {
        this.setState({
            activeStep: this.state.activeStep+1
        });
    };
    onPrevious = () => {
        this.setState({
            activeStep: this.state.activeStep-1
        });
    };

    // Feed callbacks for step 2
    // -----------------------------------------
    handleFeedChange = (key, feed) => {
        let feeds = this.state.feeds.slice(0);

        feeds[key] = feed;

        this.setState({feeds: feeds});
    };

    onAddFeed = () => {
        this.setState({
            feeds: this.state.feeds.concat([this.getBlankFeed()])
        })
    };

    onRemoveFeed = () => {
        if (this.state.feeds.length === 1) { return; }

        this.setState({
            feeds: this.state.feeds.slice(0, -1)
        })
    };

    getBlankFeed = () => {
        return {
            rssUrl: "",
            tags: [],
            urlValid: false
        }
    };

    onSubmitCode = (code) => {
        UserFetch.getFeedsFromHoldingTank(code).then((feeds) => {
            this.setState({feeds: feeds.feeds, codeUsed: true})
        }).catch((error) => {
            console.log("Unable to holding tank feeds.")
        });
    };

    // Holding tank callbacks for step 3
    // -----------------------------------------
    onPaymentClicked = () => {
        let params = {
            "feeds": this.state.feeds,
            "code_used": this.state.codeUsed
        };
        UserFetch.feedsToHoldingTank(params).then((response) => {

        }).catch((error) => {
            console.log("Unable to holding tank feeds.")
        });
    }

}

export default withStyles(styles)(PortalSignup);