// CONFIG
import AppConfig from '../appConfig'
// COMPONENTS
import PortalSignup from '../components/PortalSignup';
import PortalComplete from '../components/PortalComplete';
import PortalDashboard from '../components/PortalDashboard';
import ClipSearch from '../components/ClipSearch';
import ClipQueue from '../components/ClipQueue';
import About from '../components/About';
import ForgotPassword from '../components/ForgotPassword';
import Unsubscribe from '../components/Unsubscribe';
import TermsOfService from '../components/PortalSignup/TermsOfService';
import TutorialVideos from '../components/PortalDashboard/TutorialVideos';
import SignupLandingPge from '../components/LandingPages/SignupLandingPage';
import FootballSignupLandingPge from '../components/LandingPages/FootballSignupLandingPage';
import EmailClipSig from '../components/LandingPages/EmailClipSig';
import PlayerCardDemo from '../components/Demos/PlayerCardDemo';
import EmbedPlayerDemo from '../components/Demos/EmbedPlayerDemo';
import PrivacyPolicy from '../components/PrivacyPolicy';
// ICONS
import { Home, Info, PlaylistPlayOutlined, AddBox, Dashboard } from '@material-ui/icons';

let userRoutes = [];
if (!AppConfig.enableManyPlaylists) {
  userRoutes = [
    {
      path: '/',
      navbarName: 'Home', 
      icon: Home,
      component: ClipSearch,
      exact: true,
      strict: true,
    },
    {
      path: '/user/playlists/new',
      navbarName: AppConfig.enableManyPlaylists ? 'Listen Later' :'Playlist',
      icon: PlaylistPlayOutlined,
      component: ClipQueue,
      requiresLogin: true,
      beta: true
    },
  ];
}

if(AppConfig.enableManyPlaylists) {
  userRoutes = [
    {
      path: '/',
      navbarName: 'Home', 
      icon: Home,
      component: ClipSearch,
      exact: true,
      strict: true,
    },
  ];
}

export const UserRoutes = userRoutes;

export const NonPodcasterRoutes = [
  {
    path: '/portal/signup',
    navbarName: 'Add Your Pod',
    icon: AddBox,
    component: PortalSignup,
    requiresLogin: true,
    featureToggled: true,
    featureToggleValue: AppConfig.enableSignup
  }
];

export const PodcasterRoutes = [
  {
    path: '/portal/dashboard',
    navbarName: 'Dashboard',
    icon: Dashboard,
    component: PortalDashboard,
    requiresLogin: true
  }
];

export const HelpRoutes = [
  {
    path: '/about',
    navbarName: 'About Clipcast',
    icon: Info,
    component: About,
  },
];
// System routes are not rendered in the nav bar
export const SystemRoutes = [
  {
    path: '/portal/complete',
    component: PortalComplete,
  },
  {
    path: '/popular',
    component: ClipSearch,
    strict: true,
    exact: true,
  },
  {
    path: '/terms-of-service',
    component: TermsOfService,
    strict: true,
    exact: true,
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicy,
    strict: true,
    exact: true,
  },
  {
    path: '/demos/playercard',
    component: PlayerCardDemo,
    strict: true,
    exact: true,
  },
  {
    path: '/demos/embedplayer',
    component: EmbedPlayerDemo,
    strict: true,
    exact: true,
  },
  {
    path: '/tutorials',
    component: TutorialVideos,
    strict: true,
    exact: true,
  },
  {
    path: '/signup',
    component: SignupLandingPge,
    strict: true,
    exact: true,
  },
  {
    path: '/signup/ff',
    component: FootballSignupLandingPge,
    strict: true,
    exact: true,
  },
  {
    path: '/:clipSignature',
    component: ClipSearch,
    strict: true,
    exact: true,
  },
  {
    path: '/email/:clipSignature',
    component: EmailClipSig,
    strict: true,
    exact: true,
  },
  {
    path: '/user/playlists/:queueName',
    component: ClipQueue,
    strict: true,
    exact: true,
  },
  {
    path: '/user/forgotpassword',
    component: ForgotPassword,
    strict: true,
    exact: true,
  },
  {
    path: '/user/unsubscribe',
    component: Unsubscribe,
    strict: true,
    exact: true,
  },
]

export const Routes = [
  ...UserRoutes,
  ...NonPodcasterRoutes,
  ...PodcasterRoutes,
  ...HelpRoutes,
  ...SystemRoutes
];
