import AppConfig from '../appConfig'
import AbstractFetch from './abstractFetch';
import AppStore from './appStore';

class ClipsFetch {

    static searchClips(keywords, tags, searchUserPodcasts, preferredPodcastId, exclusivePodcastId, limit, offset) {

        var url = AppConfig.apiUrl+"/clips/search";
        return AbstractFetch.fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "keywords": keywords,
                    "tags": tags,
                    "search_user_podcasts": searchUserPodcasts ? searchUserPodcasts : false,
                    "preferred_podcast_id": preferredPodcastId ? preferredPodcastId : null,
                    "exclusive_podcast_ids": exclusivePodcastId ? [exclusivePodcastId] : null,
                    "limit": limit ? limit : null,
                    "offset": null ? !offset && offset !== 0  : offset,
                    "referrer": document.referrer ? document.referrer : null
                })
            }).then((response) => {
                return response.json()
            })
    }

    static saveClip(clip) {

        var url = AppConfig.apiUrl+"/clips";
        return AbstractFetch.fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({"clip": clip})
        }).then((response) => {
                return response.json()
            })
    }

    static generateClip(key, startTime, endTime) {

        var url = AppConfig.apiUrl+"/clips/generate";
        return AbstractFetch.fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    "key": key,
                    "start_time": startTime,
                    "end_time": endTime
                }
            )
        }).then((response) => {
                return response.json()
            })
    }

    static postClipPlayEvent(clip) {

        let userId = ClipsFetch.getUserID();

        var url = AppConfig.apiUrl+"/clips/event/play";
        return AbstractFetch.fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    "clip": clip,
                    "user_id": userId,
                    "referrer": document.referrer ? document.referrer : null
                }
            )
        }).then((response) => {
            return response.json()
        })
    }

    static postEmailClipPlayEvent(email, clip_sig) {

        var url = AppConfig.apiUrl+"/email/event/clip/click";
        return AbstractFetch.fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    "email": email,
                    "clip_sig": clip_sig
                }
            )
        }).then((response) => {
            return response.json()
        })
    }

    static fetchClip(signature) {

        var url = AppConfig.apiUrl+"/clips/"+signature;
        return AbstractFetch.fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
                return response.json()
            })
    }

    static fetchPopularClips() {

        var url = AppConfig.apiUrl+"/clips/popular";
        return AbstractFetch.fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            return response.json()
        })
    }

    static fetchPodcasts() {

        var url = AppConfig.apiUrl+"/clips/podcasts";
        return AbstractFetch.fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
                return response.json()
            })
    }

    static fetchEpisodes(podcast_ids) {

        var url = AppConfig.apiUrl+"/clips/episodes";
        return AbstractFetch.fetch(url, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({"podcast_ids": podcast_ids})
        }).then((response) => {
                return response.json()
            })
    }

    static fetchTags() {

        var url = AppConfig.apiUrl+"/clips/tags";
        return AbstractFetch.fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            return response.json()
        })
    }

    static getUserID() {
        let userInfo = AppStore.fetchUserInfo();
        if (userInfo === null) {return null;}

        return userInfo.id;
    }

}

export default ClipsFetch;