import React, { Component } from "react";
import PropTypes from 'prop-types';
// MATERIAL-UI
import Fab from '@material-ui/core/Fab';
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
// REACT-BEAUTIFUL-DND
import { DragDropContext, Droppable } from "react-beautiful-dnd";
//ICONS
import PlayArrow from "@material-ui/icons/PlayArrow";
import PauseSharp from "@material-ui/icons/PauseSharp";
import SkipNext from "@material-ui/icons/SkipNext";
import SkipPrevious from "@material-ui/icons/SkipPrevious";
// Fetch
import QueueFetch from "../../fetch/queueFetch";
import ClipsFetchs from "../../fetch/clipsFetch";
// Components
import ClipQueueBanner from "./ClipQueueBanner";
import ClipQueueCard from "./ClipQueueCard";
import ClipQueueToolbar from './ClipQueueToolbar';
import EmptyState from "./EmptyState";
import AppConfig from '../../appConfig';

const styles = theme => ({
  root: {
    display: "flex"
  },
  droppableRoot: {
    width: "100%"
  },
  buttonBar: {
    align: "center",
    textAlign: "center",
    marginTop: 0,
    marginBottom: 0,
    bottom: theme.spacing.unit * 5,
    left: 0,
    position: 'fixed',
    zIndex: 10000,
    width: '100%',
  },
  fab: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit ,
  },
  bottomSpacer: {
    height: '100px',
    display: 'block'
  }
});

class ClipQueue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      queue: null,
      playing: false,
      currentPlayerIndex: null,
      playedClips: [],
      didReorderFail: false
    };
  }

  render() {
    if (this.state.queue === null) {
      return null;
    }

    const { classes } = this.props;
    const { queue } = this.state;

    const waitingHeight = 60;
    const waitingTop = window.innerHeight / 2 - waitingHeight / 2;
    const waitingLeft = window.innerWidth / 2 - waitingHeight / 2;

    const ButtonIcon = this.state.playing ? PauseSharp : PlayArrow;

    if (this.state.queue && this.state.queue.clips.length === 0) {
      return (
        <div className={classes.root}>
          <Grid container>
            <Grid item xs={12}>
              <ClipQueueBanner />
            </Grid>
            {AppConfig.enableManyPlaylists &&
              <Grid item xs={12}>
                <ClipQueueToolbar 
                  onDeleteQueue={this.onDeleteQueue}
                  onRenameQueue={this.onRenameQueue}
                  playing={this.state.playing}
                  queue={queue}  
                />
              </Grid>
            }
            <Grid item xs={12} >
              <EmptyState />
            </Grid>
          </Grid>
        </div>
      );
    }

    if (this.state.queue === null) {
      return null;
    }

    return (
      <div className={classes.root}>
        <div className={classes.buttonBar}>
          <Fab
            className={classes.fab}
            variant="round"
            color="primary"
            aria-label="Add"
            onClick={this.onPreviousTrack}
            >
            <SkipPrevious />
          </Fab>
          <Fab
            className={classes.fab}
            variant="round"
            color="primary"
            aria-label="Add"
            onClick={this.onPlayPause}
            >
            <ButtonIcon />
          </Fab>
          <Fab
            className={classes.fab}
            variant="round"
            color="primary"
            aria-label="Add"
            onClick={this.onNextTrack}
            >
            <SkipNext />
          </Fab>
        </div>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Grid container spacing={24} direction="row" className={classes.gridContainer}>
            <Grid item xs={12} align="center">
              <ClipQueueBanner />
            </Grid>
            {AppConfig.enableManyPlaylists && 
              <Grid item xs={12}>
                <ClipQueueToolbar 
                  onDeleteQueue={this.onDeleteQueue}
                  onRenameQueue={this.onRenameQueue}
                  playing={this.state.playing}
                  queue={queue}  
                />
              </Grid>
            }
            <Droppable droppableId="ClipQueue">
              {provided => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className={classes.droppableRoot}
                >
                  <Grid item xs={12} align="center">
                    {this.state.queue.clips.map((clip, index) => (
                      <ClipQueueCard
                        clip={clip}
                        disabled={this.state.currentPlayerIndex !== index}
                        index={index}
                        key={index}
                        onClipRemoved={this.onClipRemoved}
                        startPlaying={
                          this.state.playing && this.state.currentClip === clip
                        }
                      />
                    ))}
                    <div className={classes.bottomSpacer}></div>
                  </Grid>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </Grid>
        </DragDropContext>
        {this.state.loading && (
          <CircularProgress
            size={waitingHeight}
            style={{
              margin: "auto",
              position: "absolute",
              left: waitingLeft,
              top: waitingTop
            }}
          />
        )}
      </div>
    );
  }

  // COMPONENT LIFECYCLE
  componentDidMount() {
    if(AppConfig.enableManyPlaylists) {
      if(! this.props || 
        !this.props.match || 
        !this.props.match.params || 
        !this.props.match.params.queueName) {
        // if there is no queue parameter
        this.getUserQueue('new');
      }
      const { queueName } = this.props.match.params;
      this.getUserQueue(queueName);
    }
    if(!AppConfig.enableManyPlaylists) {
      this.getUserQueue('new');
    }
  }

  componentDidUpdate(prevProps) {
    if(AppConfig.enableManyPlaylists) {
      // if the queueName from the url changed
      if(prevProps.match.params.queueName !== this.props.match.params.queueName) {
        const { queueName } = this.props.match.params;
        this.getUserQueue(queueName);
      }
    }
  }
  // CLASS METHODS

  attachListeners = () => {
    document
      .getElementById(
        this.state.queue.clips[this.state.currentPlayerIndex].signature
      )
      .addEventListener("pause", this.onClipEnd);
  };

  detachListeners = () => {
    document
      .getElementById(
        this.state.queue.clips[this.state.currentPlayerIndex].signature
      )
      .removeEventListener("pause", this.onClipEnd);
  };

  getUserQueue = (queueName) => {
    this.setState({ loading: true }, () => {
      QueueFetch.getQueue(queueName)
        .then(queue => {
          this.setState({
            queue: queue,
            currentPlayerIndex: 0,
            loading: false
          });
        })
        .catch(error => {
          console.log("unable to load playlist");
          this.setState({
            queue: { clips: [] } //bit of a hack, but we should only be here if the playlist doesn't exist yet
          });
        });
    });
  };

  onClipEnd = () => {
    if (this.state.queue.clips.length - 1 > this.state.currentPlayerIndex) {
      this.setState(
        {
          currentPlayerIndex: this.state.currentPlayerIndex + 1,
          playing: false
        },
        () => {
          setTimeout(() => {
            this.onPlayPause();
          }, 1000);
        }
      );
    } else {
      this.setState({ playing: false });
    }
  };

  onClipRemoved = clip => {
    let queueName = ''
    if(!AppConfig.enableManyPlaylists){
      queueName = 'new';
    }

    if(AppConfig.enableManyPlaylists) {
      if (!this.state.queue.queue_name) {
        return null;
      }
      queueName = this.state.queue.queue_name;
    }
    this.setState({ loading: true }, () => {
      QueueFetch.removeClipsFromQueue(queueName, [clip]).then(queue => {
        this.setState({
          queue: queue,
          loading: false
        });
      });
    });
  };

  onDeleteQueue = (queueName) =>{
    if(!this.props || !this.props.getUserInfo) {
      return null;
    }
    const { getUserInfo, history } = this.props;

    this.setState({ loading: true }, () => {
      QueueFetch.deleteQueue(queueName)
        .then(() => {
          this.setState({
            loading: false
          });
          // refresh the playlist queue
          getUserInfo();
          // navigate away
          history.push('/');
        })
        .catch(error => {
          console.log("unable to delete playlist");
        });
    });
  }

  onDragEnd = result => {
    const { queue } = this.state;
    const { clips, queue_name } = queue;
    const { destination, source } = result;

    if (!destination) {
      // if not dropped in a droppable
      return null;
    }

    if (destination.index === source.index) {
      // if dropped back in the same spot
      return null;
    }

    const newClipList = [...clips];

    // reorder the queue
    const clipToMove = newClipList[source.index];
    newClipList.splice(source.index, 1);
    newClipList.splice(destination.index, 0, clipToMove);

    // create an object to pass to the api end point
    const newQueue = {
      queue_name: queue_name,
      clips: newClipList
    };

    const newState = {
      ...this.state,
      queue: {
        //...this.state.queue,
        clips: newClipList,
        queue_name: queue_name
      },
      didReorderFail: false
    };

    // optimistically update the UI
    this.setState(newState);

    // then let the server know what happened
    QueueFetch.reorderClipsInQueue(newQueue)
      .then(queue => {
        console.log("server queue updated");
        // dont really need to do anything here
      })
      .catch(error => {
        let newState = {
          ...this.state,
          didReorderFail: true,
          error: error.message
        };
        this.setState(newState);
      });
  };
  onNextTrack = () => {
    this.detachListeners();
    let player = document.getElementById(
      this.state.queue.clips[this.state.currentPlayerIndex].signature
    );
    player.pause();

    if (this.state.queue.clips.length - 1 > this.state.currentPlayerIndex) {
      this.setState(
        {
          currentPlayerIndex: this.state.currentPlayerIndex + 1,
          playing: false
        },
        () => {
          this.onPlayPause();
        }
      );
    } else {
      this.setState({ playing: false });
    }
  };

  onPlayPause = () => {
    let clip = this.state.queue.clips[this.state.currentPlayerIndex];

    if (
      !this.state.playedClips.includes(clip.signature) &&
      !this.state.playing &&
      !this.state.playedOnce
    ) {
      // if we're not playing, then we are about to be. log a play event
      let clip = this.state.queue.clips[this.state.currentPlayerIndex];
      ClipsFetchs.postClipPlayEvent(clip)
        .then(response => {
          this.setState({
            playedClips: this.state.playedClips.concat(clip.signature)
          });
        })
        .catch(error => {});
    }

    this.setState({ playing: !this.state.playing }, () => {
      this.playPause();
    });
  };

  onPreviousTrack = () => {
    this.detachListeners();
    let player = document.getElementById(
      this.state.queue.clips[this.state.currentPlayerIndex].signature
    );
    player.pause();

    if (this.state.currentPlayerIndex > 0) {
      this.setState(
        {
          currentPlayerIndex: this.state.currentPlayerIndex - 1,
          playing: false
        },
        () => {
          this.onPlayPause();
        }
      );
    } else {
      this.setState({ playing: false });
    }
  };

  onRenameQueue = () => {
    return null;
  }

  playPause = () => {
    let player = document.getElementById(
      this.state.queue.clips[this.state.currentPlayerIndex].signature
    );
    if (this.state.playing) {
      player.play();
      this.attachListeners();
    } else {
      this.detachListeners();
      player.pause();
    }
  };
}

ClipQueue.propTypes = {
  getUserInfo: PropTypes.func.isRequired,
  userInfo: PropTypes.shape({
    email: PropTypes.string,
    loggedIn: PropTypes.bool,
    queues: PropTypes.array
  }).isRequired,
};
export default withStyles(styles)(ClipQueue);
