import React from 'react';
import PropTypes from 'prop-types';
// MATERIAL-UI
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';
// ICONS
import Edit from '@material-ui/icons/Edit';
import Share from '@material-ui/icons/ShareOutlined';
import PlusOneIcon from '@material-ui/icons/PlusOne';
// COLORS
import red from '@material-ui/core/colors/red';
// LIBS
import Moment from 'react-moment';
import AppConfig from '../../../appConfig';
// FETCH
import AppStore from '../../../fetch/appStore'
import QueueFetch from '../../../fetch/queueFetch';
// COMPONENTS
import ClipPlayer from '../../common/clipPlayer';
import AddRemoveButton from './AddRemoveButton';
import AddRemoveListButton from './AddRemoveListButton';

const styles = theme => ({
  card: {
      margin: theme.spacing.unit,
  },
  cardHeader: {
      padding: theme.spacing.unit,
  },
  cardContent: {
      padding: theme.spacing.unit,
  },
  paddedText: {
      padding: theme.spacing.unit,
  },
  removeCircle: {
    color: red[800],
  },
});

class ClipCard extends React.Component {

  constructor(props) {
      super(props);
      this.state = {
          editMode: false,
          addToQueueEnabled: true,
          clipDuration: null
      };
  }

  render() {
      if (!this.props.clip) {
          return null;
      }

      const { classes, clip, userInfo } = this.props;
      const { 
          start_time,
          end_time,
          html_blurb,
          podcast_name,
          entry_name,
          tags,
      } = clip;

      let startMinutes = Math.floor(start_time / 60);
      let startSeconds = this.pad(Math.round(start_time - startMinutes * 60), 2);

      let endMinutes = Math.floor(end_time / 60);
      let endSeconds = this.pad(Math.round(end_time - endMinutes * 60), 2);

      let published = Date.parse(this.props.clip.published);

      let episodeUrl = this.props.clip.site_url ? this.props.clip.site_url : this.props.clip.url;

      return (
          <Card className={classes.card}>
              <CardHeader 
                  title={podcast_name}
                  subheader={entry_name}
                  className={classes.cardHeader}
              />
              <CardContent
                className={classes.cardContent}
              >
                  <Typography 
                    variant="body1" 
                    dangerouslySetInnerHTML={{ __html: html_blurb }} 
                    className={classes.paddedText}
                  />
                  <div className={classes.paddedText}> 
                      {tags && tags.slice(tags.length-2).map(tag => (
                        <Chip key={tag} label={tag} style={{marginRight: "4px"}}/>
                      ))}
                  </div>
                  <Typography variant="body2" color="textSecondary" className={classes.paddedText}>
                      <i><Moment format="ddd MMM DD YYYY hh:mm:ss A" date={published} /></i>
                  </Typography>
                  <Typography variant="body1" className={classes.paddedText}>
                      <b>{startMinutes}:{startSeconds}</b> to <b>{endMinutes}:{endSeconds}</b>
                  </Typography>
                  <ClipPlayer
                      editMode={this.state.editMode}
                      clip={clip}
                      audioDuratoinCallback={this.audioDuratoinCallback}
                  />
              </CardContent>
              <CardActions>
                  <Button
                      onClick={() => {window.open(episodeUrl, '_blank');}}
                      size="small"
                  >Listen to Episode</Button>
                  {this.props.clip.subscribe_url && <Button
                      onClick={() => {window.open(this.props.clip.subscribe_url, '_blank');}}
                      size="small"
                  >Subscribe</Button>}
                  < Tooltip title="Add 1 minute">
                      <IconButton aria-label="Add 1 Minute"
                                  onClick={this.onAddMinute}
                                  style={{marginLeft: 'auto'}}
                                  disabled={this.state.clipDuration === null}>
                          <PlusOneIcon />
                      </IconButton>
                  </Tooltip>
                  <Tooltip title="Share Clip" placement="top">
                      <IconButton aria-label="Share clip" onClick={this.onShareClip}>
                          <Share />
                      </IconButton>
                  </Tooltip>

                  {false && 
                    <Tooltip title="Edit Clip" placement="top">
                      <IconButton aria-label="Edit clip" onClick={this.onEditClip}>
                          <Edit />
                      </IconButton>
                    </Tooltip>
                  }
                  {!AppConfig.enableManyPlaylists &&
                      <AddRemoveButton 
                        added={this.state.added}
                        disabled={!this.state.addToQueueEnabled}
                        onAddToQueue={this.onAddToQueue}
                        onRemoveFromQueue={this.onRemoveFromQueue}
                      />
                  }
                  {AppConfig.enableManyPlaylists && 
                      <AddRemoveListButton 
                          clipSignature={this.props.clip.signature}
                          disabled={!this.state.addToQueueEnabled}
                          onAddToQueue={this.onAddToSelectedQueue}
                          onRemoveFromQueue={this.onRemoveFromQueue}
                          userInfo={userInfo}
                      />
                  }
              </CardActions>
          </Card>
      );
  }

  shouldComponentUpdate(nextProps, nextState) {
      // we hit an issue around 1-26-2019 where the UI seemes slow and unresponsive if there were cards on the search
      // page. after further review, we determined that these cards re-rendering was causing the slowdown.
      //
      // while the cards should re-render and determine if changes happened in the DOM, it was all of a sudden slow. the
      // code deployed to test did not exhibit this behavior. further, reverting code to that same commit, and even
      // rebuilding the npm packages from that point in time still showed the same slowdown. we aren't sure why.
      //
      // the temporary fix is to make the cards not re-render when parent components change. this code should force it.
      // this should be safe, the values on the card at this level are static data and don't need to be re-rendered. the
      // child components of this component, mainly the ClipPlayer, can still re-render based on internal state change,
      // which is what we want. so parent renders will never trickle down, but child components can still re-render
      // themselves through internal state. if this becomes a problem, where we need parent-level renders to ever
      // trickle down, we will have to remove this code and try to understand the root cause.
      if (
        this.state.added !== nextState.added || //
        this.state.addToQueueEnabled !== nextState.addToQueueEnabled ||
        this.state.clipDuration !== nextState.clipDuration ||
        this.props.userInfo.loggedIn !== nextProps.userInfo.loggedIn  || // user logged in or out
        this.props.userInfo.queues !== nextProps.userInfo.queues || // queue list was chnaged
        this.props.clip.end_time !== nextProps.clip.end_time // the user can hit the +1 minute button

      ) {
          return true;
      }
      return false;
  }

  addClipsToQueue = (queueName) => {

    const { clip, setSnackMessage } = this.props;

    QueueFetch.addClipsToQueue(queueName, [clip]).then(() => {
      setSnackMessage(
        `Clip added to ${queueName === 'new' ? 'Listen later' : queueName }`);
      this.setState({addToQueueEnabled: true, added: true});
    }).catch((status) => {
      if (status.message === '409') {
          // conflict code, means they tried to add the same clip to the playlist twice
          this.props.setSnackMessage(
            `Clip already added to ${queueName === 'new' ? 'Listen later' : queueName}`
          );
          this.setState({addToQueueEnabled: true, added: true});
      } else {
          this.props.setSnackMessage("Unable to Add");
          this.setState({addToQueueEnabled: true});
      }
  });

  }
  isUserLoggedIn = () => {
    if(!this.props 
      || !this.props.userInfo
      || !this.props.userInfo.loggedIn) {     
      return false;
    } else {
      return true;
    }
  }
  /**
   * This version of onAddToQueue is retained for the single Playlist feature,
   * it can be removed once the enableManyPlaylists toggle is flipped permanently
   */
  onAddToQueue = () => {
    let userInfo = AppStore.fetchUserInfo();
    if (userInfo === null || !userInfo.loggedIn) {
        this.props.setSnackMessage("Please login before adding to Playlist");
        return;
    }

    this.setState({addToQueueEnabled: false}, () => {
        QueueFetch.addClipsToQueue("new", [this.props.clip]).then(() => {
            this.props.setSnackMessage("Added");
            this.setState({addToQueueEnabled: true, added: true});
        }).catch((status) => {
            if (status.message === '409') {
                // conflict code, means they tried to add the same clip to the playlist twice
                this.props.setSnackMessage("Clip already added to playlist");
                this.setState({addToQueueEnabled: true, added: true});
            } else {
                this.props.setSnackMessage("Unable to Add");
                this.setState({addToQueueEnabled: true});
            }
        });
    });
  };


  /**
   * Version of onAddToQueue for use in the feature toggled multiple playlist feature
   * 
   * 
   */
  onAddToSelectedQueue = (queueName, mode = 'add') => {

    if(!this.isUserLoggedIn()) {
      this.props.setSnackMessage("Please login before adding to Playlist");
      return null
    }

    const { clip, getUserInfo } = this.props;
    this.setState({addToQueueEnabled: false}, () => {
        QueueFetch.addClipsToQueue(queueName, [clip])
        .then(() => {
            this.props.setSnackMessage(
              `Clip added to ${queueName === 'new' ? 'Listen later' : queueName }`
            );
            this.setState({addToQueueEnabled: true, added: true});
        })
        .then(() => {
          // if we are adding a new queue then refresh the queue list in userInfo
          // this is a bit of a hack, but will do to leep it simple and make it work
          // for now
          if(mode === 'create') {
            getUserInfo();
          }
        })
        .catch((status) => {
            if (status.message === '409') {
                // conflict code, means they tried to add the same clip to the playlist twice
                this.props.setSnackMessage(
                  `Clip already added to ${queueName === 'new' ? 'Listen later' : queueName}`
                );
                this.setState({addToQueueEnabled: true, added: true});
            } else {
                this.props.setSnackMessage("Unable to Add");
                this.setState({addToQueueEnabled: true});
            }
        });
    });
  };

  onRemoveFromQueue = () => {
      let userInfo = AppStore.fetchUserInfo();
      if (userInfo === null || !userInfo.loggedIn) {
          this.props.setSnackMessage("Please login before adding to Playlist");
          return;
      }

      this.setState({addToQueueEnabled: false}, () => {
          QueueFetch.removeClipsFromQueue("new", [this.props.clip]).then(() => {
              this.props.setSnackMessage("Removed")
              this.setState({addToQueueEnabled: true, added: false});
          }).catch(() => {
              this.props.setSnackMessage("Unable to Remove")
              this.setState({addToQueueEnabled: true});
          });
      });
  };

  onEditClip = () => {
      this.setState({editMode: !this.state.editMode});
  };

  onShareClip = () => {
    this.props.onShareClip(this.props.clip);
  };

  onAddMinute = () => {
    const timeToAdd = this.props.clip.end_time + 60 > this.state.clipDuration
        ? this.state.clipDuration - this.props.clip.end_time : 60;
    this.props.onAddMinute(this.props.clip, timeToAdd);
  };

  audioDuratoinCallback = (duration) => {
    this.setState({clipDuration: duration});
  }

  pad = (num, size) => {
      var s = String(num);
      while (s.length < (size || 2)) {s = "0" + s;}
      return s;
  };
}

ClipCard.propTypes = {
  clip: PropTypes.object.isRequired,
  getUserInfo: PropTypes.func.isRequired,
  onShareClip: PropTypes.func.isRequired,
  onAddMinute: PropTypes.func.isRequired,
  setSnackMessage: PropTypes.func.isRequired,
  userInfo: PropTypes.object,
}

ClipCard.defaultProps ={
  userInfo: {},
}
export default withStyles(styles)(ClipCard);
