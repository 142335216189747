import React, { Component } from 'react';
// MATERIAL UI
import Grid from '@material-ui/core/Grid';
// FETCH
import ClipsFetchs from '../../fetch/clipsFetch';
// COMPONENTS
import Podcast from '../common/Podcast';

class PodcastsList extends Component {
  constructor(props) {
      super(props);
      this.state = {
          podcasts: null
      };
  }

  render() {
      if (this.state.podcasts === null) {
          return null;
      }

      const { podcasts } = this.state;

      return (
          <div style={{align: "center"}} align='center'>
              <Grid container spacing={24} align="center">
                  {podcasts.map((podcast, key) => (
                        <Grid item key={key} align="center" xs={12} sm={6} md={4} lg={3}>
                            <Podcast podcast={podcast} />
                        </Grid>
                  ))}
              </Grid>
          </div>
      );
  }

  componentDidMount() {
      ClipsFetchs.fetchPodcasts().then((podcasts) => {
          this.setState({podcasts: podcasts})
      }).catch((error) => {
          console.log('unable to fetch list')
      });
  }
}

export default PodcastsList;
