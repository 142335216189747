import React, { Component } from "react";

// MATERIAL-UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
    root: {
        margin: theme.spacing.unit
    },
    videoDiv: {
        width: "80%"
    },
    paper: {
        padding: theme.spacing.unit * 3,
        textAlign: "center"
    }
});

class TutorialVideos extends Component {

    render() {

        const { classes } = this.props;

        let videoWidth = Math.max(300, 0.7*window.innerWidth).toString()+"px";

        return (
            <Grid
                container
                spacing={24}
                direction="column"
                justify="center"
                alignItems="center"
            >
                <br/><br/>
                <Typography variant={'h4'}>
                    Tutorial Videos
                </Typography>
                <Typography variant={'body1'}>
                    Videos to highlight the features of Clipcast
                </Typography>
                <br/>


                <Paper className={classes.paper}>
                    <video
                        width={videoWidth}
                        controls
                        src={process.env.PUBLIC_URL + "/media/clipcast_generator.mp4"}
                    />
                    <Typography variant={'body1'}>
                        <b>Generate Clips</b>
                    </Typography>
                </Paper>
                <br/><br/>

                <Paper className={classes.paper}>
                    <video
                        width={videoWidth}
                        controls
                        src={process.env.PUBLIC_URL + "/media/clipcast_share.mp4"}
                    />
                    <Typography variant={'body1'}>
                        <b>Share a Clip</b>
                    </Typography>
                </Paper>
                <br/><br/>

                <Paper className={classes.paper}>
                    <video
                        width={videoWidth}
                        controls
                        src={process.env.PUBLIC_URL + "/media/clipcast_playlist.mp4"}
                    />
                    <Typography variant={'body1'}>
                        <b>Create a Playlist</b>
                    </Typography>
                </Paper>
                <br/><br/>

                <Paper className={classes.paper}>
                    <video
                        width={videoWidth}
                        controls
                        src={process.env.PUBLIC_URL + "/media/clipcast_user_feedback.mp4"}
                    />
                    <Typography variant={'body1'}>
                        <b>Provide Feedback</b>
                    </Typography>
                </Paper>
                <br/><br/>

                <Paper className={classes.paper}>
                    <video
                        width={videoWidth}
                        controls
                        src={process.env.PUBLIC_URL + "/media/clipcast_using_filters.mp4"}
                    />
                    <Typography variant={'body1'}>
                        <b>Use Filters</b>
                    </Typography>
                </Paper>
                <br/>

            </Grid>
        )
    }
}

export default withStyles(styles)(TutorialVideos);