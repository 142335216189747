import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
// MATERIAL-UI
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
// INTERNAL COMPONENTS
import SimpleSignup from '../common/simpleSignup'

const styles = theme => ({
    container: {
        margin: theme.spacing.unit * 2,
        textAlign: "center"
    },
    card: {
        margin: theme.spacing.unit * 2
    },
    responsiveImage: {
        height: "auto",
        boxShadow: "0 0 8px 8px white inset",
        width: "95%"
    },
    vertialAlign: {
        verticalAlign: "middle"
    },
    centerText: {
        textAlign: "center"
    }
});

class SignupLandingPage extends Component {

    render() {

        const { classes } = this.props;

        return (
            <div>
                <Card className={classes.card}>
                    <CardContent>
                        <Grid
                            className={classes.container}
                            container
                        >
                            <Grid item xs={4}>
                                <div className={classes.vertialAlign}>
                                    <Typography variant={'h5'}>
                                        Ever wish you could search your favorite podcasts?
                                    </Typography>
                                    <Typography variant={'p'}>
                                        Well now you can. Search your podcasts and get specific clips of what you want to hear.
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={8}>
                                <img
                                    className={classes.responsiveImage}
                                    alt=""
                                    align="center"
                                    src={process.env.PUBLIC_URL + "/main_search.png"}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Card className={classes.card}>
                    <CardContent>
                        <Grid
                            className={classes.container}
                            container
                        >
                            <Grid item xs={4}>
                                <Typography variant={'h5'} className={classes.vertialAlign}>
                                    Suggested Content.
                                </Typography>
                                <Typography variant={'p'} className={classes.vertialAlign}>
                                    Get notified about new episodes or clips and get suggestions that
                                    matter to you.
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <img
                                    className={classes.responsiveImage}
                                    alt=""
                                    align="center"
                                    src={process.env.PUBLIC_URL + "/email_suggestions.png"}
                                    height="250px"
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Card className={classes.card}>
                    <CardContent>
                        <Grid
                            className={classes.container}
                            container
                        >
                            <Grid item xs={4}>
                                <img
                                    className={classes.mainSearchImg}
                                    alt=""
                                    align="center"
                                    src={process.env.PUBLIC_URL + "/app.png"}
                                    height="250px"
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant={'h5'} className={classes.vertialAlign}>
                                    Download our app.
                                </Typography>
                                <Typography variant={'p'} className={classes.vertialAlign}>
                                    Portable search so you can listen to your favorite clips whenever you want.
                                </Typography>
                                <Grid item xs={12} align="center" className={classes.badgeBar}>
                                    <a href="https://apps.apple.com/app/id1469480446" target="_blank" rel="noopener noreferrer">
                                        <img
                                            alt=""
                                            align="center"
                                            src={process.env.PUBLIC_URL + "/app_store_download.svg"}
                                            height="42px"
                                        />
                                    </a>
                                    <a href="https://play.google.com/store/apps/details?id=com.reactnativeclipcast" target="_blank" rel="noopener noreferrer">
                                        <img
                                            alt=""
                                            align="center"
                                            src={process.env.PUBLIC_URL + "/google_play_download.png"}
                                            height="60px"
                                        />
                                    </a>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Card className={classes.card}>
                    <CardContent>
                        <Typography variant={'h5'} className={classes.centerText}>
                            Sign up now to get started
                        </Typography>
                        <Grid
                            className={classes.container}
                            container
                        >
                            <Grid item xs={3}/>
                            <Grid item xs={6}>
                                <SimpleSignup/>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>


        );
    }
}

export default withStyles(styles)(SignupLandingPage);