import React from 'react';
import PropTypes from 'prop-types';
// MATERIAL-UI
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const DeletePlaylistDialog = (props) => {
  if(!props) {
    return null;
  }

  const { onCancelDelete, onConfirmDelete, showDeleteDialog, playlistName } = props;

  return (
    <Dialog
      open={showDeleteDialog}
      onClose={onCancelDelete}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{`Delete playlist ${playlistName}?`}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {`Are you sure you want to delete ${playlistName}? All clips will be deleted and cannot be recovered.`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancelDelete} color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirmDelete} color="primary" autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DeletePlaylistDialog.propTypes = {
  showDeleteDialog: PropTypes.bool.isRequired,
  onConfirmDelete: PropTypes.func.isRequired,
  onCancelDelete: PropTypes.func.isRequired,
  playlistName: PropTypes.string.isRequired,
}

export default DeletePlaylistDialog;