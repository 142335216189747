import React from 'react';
import ReactDOM from 'react-dom';
// LIBS
import * as serviceWorker from './serviceWorker';
import ReactGA from 'react-ga';
// MATERIAL-UI
import { MuiThemeProvider } from '@material-ui/core/styles';
// COMPONENTS
import App from './App';
// STYLES
import theme from './styles/MuiTheme';
import './index.css';
// SENTRY
import * as Sentry from '@sentry/browser';

if (process.env.REACT_APP_ENV === "prod") {
    ReactGA.initialize('UA-129031546-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
}

if (process.env.REACT_APP_ENV === "prod") {
    Sentry.init({
        dsn: "https://4a8cd154eaaf4f0f8cacc2da0dbb1791@sentry.io/1390616"
    });
} else if (process.env.REACT_APP_ENV === "test") {
    Sentry.init({
        dsn: "https://71da9b0767c54685a5d7df70c3323621@sentry.io/1390619"
    });
}



ReactDOM.render(
    <MuiThemeProvider theme={theme}>
        <App />
    </MuiThemeProvider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
