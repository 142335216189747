import AppConfig from '../appConfig'
import AbstractFetch from './abstractFetch';

class QueueFetch {

    static addClipsToQueue(queue_name, clips) {
      const url = AppConfig.apiUrl + "/queue/clips";
        return AbstractFetch.fetch(url, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({"queue_name": queue_name, "clips": clips})
        }).then((response) => {
            return response.json()
        })
    }
    
    static deleteQueue(queue_name) {
      const url = AppConfig.apiUrl + "/queue";
      return AbstractFetch.fetch(url, {
          credentials: 'include',
          method: 'DELETE',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({"queue_name": queue_name})
      }).then((response) => {
          return response.json()
      })
    }

    static getQueue(queue_name) {
      const url = AppConfig.apiUrl + "/queue?queue_name="+queue_name;
      return AbstractFetch.fetch(url, {
        credentials: 'include',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
      }).then((response) => {
        return response.json()
      })
    }

    static removeClipsFromQueue(queue_name, clips) {
      const url = AppConfig.apiUrl + "/queue/clips";
        return AbstractFetch.fetch(url, {
            credentials: 'include',
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({"queue_name": queue_name, "clips": clips})
        }).then((response) => {
                return response.json()
            })
    }

    static reorderClipsInQueue(queue) {
        const url = AppConfig.apiUrl + "/queue/clips";
        return AbstractFetch.fetch(url, {
            credentials: 'include',
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({"queue_name": queue.queue_name, "clips": queue.clips})
        }).then((response) => {
            return response.json()
        })
    }
}

export default QueueFetch;