import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
// MATERIAL-UI
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
// INTERNAL COMPONENTS
import SimpleSignup from '../common/simpleSignup'

const styles = theme => ({
    container: {
        marginTop: theme.spacing.unit * 2,
        textAlign: "center"
    },
    card: {
        marginTop: theme.spacing.unit * 2
    },
    responsiveImage: {
        height: "auto",
        boxShadow: "0 0 8px 8px white inset",
        width: "95%",
        maxWidth: "850px"
    },
    responsiveAppImage: {
        height: "auto",
        boxShadow: "0 0 8px 8px white inset",
        maxHeight: "250px"
    },
    vertialAlign: {
        marginTop: "30px"
    },
    centerText: {
        textAlign: "center"
    }
});

class FootballSignupLandingPage extends Component {

    render() {

        const { classes } = this.props;

        let smallWidth = 500;
        let medWidth = 725;
        let centerGridWidth = window.innerWidth > smallWidth ? 8 : 12;

        return (
            <div>
                <Card className={classes.card}>
                    <CardContent>
                        <Grid
                            className={classes.container}
                            container
                        >
                            {window.innerWidth > smallWidth && <Grid item xs={2} />}
                            <Grid item xs={centerGridWidth}>
                                <div className={classes.vertialAlign}>
                                    <Typography variant={'h5'}>
                                        Ever wish you could search your favorite fantasy football podcasts?
                                    </Typography>
                                    <Typography variant={'p'}>
                                        <i>Well now you can. Type in any player or team and get specific clips of what you want to hear.</i>
                                    </Typography>
                                </div>
                                <br/>
                                <img
                                    className={classes.responsiveImage}
                                    alt=""
                                    align="center"
                                    src={process.env.PUBLIC_URL + "/main_search.png"}
                                />
                                <div className={classes.vertialAlign}>
                                    <Typography variant={'h5'}>
                                        Dominate your Fantasy Football Research
                                    </Typography>
                                    <Typography variant={'p'}>
                                        <i>Get insights and opinions about any player from multiple podcasts instantly.</i>
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Card className={classes.card}>
                    <CardContent>
                        <Grid
                            className={classes.container}
                            container
                        >
                            {window.innerWidth > smallWidth && <Grid item xs={2} />}
                            <Grid item xs={centerGridWidth}>
                                <Typography variant={'h5'} className={classes.vertialAlign}>
                                    Suggested Clips
                                </Typography>
                                <Typography variant={'p'}>
                                    <i>Get notified about new episodes or clips and get suggestions that
                                        matter to you.</i>
                                </Typography>
                                <br/>
                                <img
                                    className={classes.responsiveImage}
                                    alt=""
                                    align="center"
                                    src={process.env.PUBLIC_URL + "/email_suggestions.png"}
                                    height="250px"
                                />
                                <Typography variant={'h5'} className={classes.vertialAlign}>
                                    Curated content delivered right to you
                                </Typography>
                                <Typography variant={'p'}>
                                    <i>Find out about trending topics and players first, no need to listen to hours of episodes.</i>
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Card className={classes.card}>
                    <CardContent>
                        <div className={classes.container}>
                            {window.innerWidth <= smallWidth && <img
                                        className={classes.responsiveAppImage}
                                        alt=""
                                        align="center"
                                        src={process.env.PUBLIC_URL + "/app.png"}
                                    />}
                            <Grid
                                className={classes.container}
                                container
                            >
                                {window.innerWidth > medWidth && <Grid item xs={2}/>}
                                {window.innerWidth > smallWidth && <Grid item xs={2}>
                                    <img
                                        className={classes.responsiveAppImage}
                                        alt=""
                                        align="center"
                                        src={process.env.PUBLIC_URL + "/app.png"}
                                    />
                                </Grid>}
                                {window.innerWidth <= medWidth && <Grid item xs={2}/>}
                                <Grid item xs={centerGridWidth}>
                                    <Typography variant={'h5'} className={classes.vertialAlign}>
                                        Download our app
                                    </Typography>
                                    <Typography variant={'p'} className={classes.vertialAlign}>
                                        Portable search so you can listen to your favorite clips whenever you want.
                                    </Typography>
                                    <Grid item xs={12} align="center" className={classes.badgeBar}>
                                        <a href="https://apps.apple.com/app/id1469480446" target="_blank" rel="noopener noreferrer">
                                            <img
                                                alt=""
                                                align="center"
                                                src={process.env.PUBLIC_URL + "/app_store_download.svg"}
                                                height="42px"
                                            />
                                        </a>
                                        <a href="https://play.google.com/store/apps/details?id=com.reactnativeclipcast" target="_blank" rel="noopener noreferrer">
                                            <img
                                                alt=""
                                                align="center"
                                                src={process.env.PUBLIC_URL + "/google_play_download.png"}
                                                height="60px"
                                            />
                                        </a>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </CardContent>
                </Card>
                <Card className={classes.card}>
                    <CardContent>
                        <Typography variant={'h5'} className={classes.centerText}>
                            Sign up now to get started
                        </Typography>
                        <Grid
                            className={classes.container}
                            container
                        >
                            {window.innerWidth > smallWidth && <Grid item xs={2} />}
                            <Grid item xs={centerGridWidth}>
                                <SimpleSignup/>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>


        );
    }
}

export default withStyles(styles)(FootballSignupLandingPage);
