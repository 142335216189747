import React, { Component } from "react";

// MATERIAL-UI
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
//Components
import ClipFilters from '../common/clipFilters';

const styles = theme => ({
    card: {
        margin: theme.spacing.unit
    }
});


class RSSFeed extends Component {
    state = {
        filtersOpen: false
    };

    render() {
        const { classes } = this.props;

        return (
            <Card className={classes.card}>
                <CardContent>
                    <Grid
                        container
                        spacing={24}
                        direction="row"
                        justify="center"
                        alignItems="center"
                    >
                        <Grid item xs={12} align='center'>

                            <TextField
                                error={!this.props.feed.urlValid}
                                label="RSS URL."
                                value={this.props.feed.rssUrl}
                                margin="normal"
                                type="url"
                                style={{width: "80%"}}
                                onChange={this.handleURLChange}
                                disabled={this.props.disableSelection}
                            />
                        </Grid>

                        <br/>

                        <ClipFilters
                            onClose={this.onFiltersClose}
                            open={this.state.filtersOpen}
                            tags={this.props.feed.tags}
                            setTags={this.setTags}
                            filterLabel="Apply these tags to my podcast"
                        />
                        <Grid item xs={12} align='center'>
                            <Button variant="contained" color="primary" onClick={this.onFiltersOpen} disabled={this.props.disableSelection}>
                                Add Tags
                            </Button>
                        </Grid>

                        <Grid item xs={12} align='center'>
                            <Typography variant={'body1'}>
                                Currently selected tags:
                            </Typography>
                        </Grid>

                        <Grid item xs={12} align='center'>
                            {this.props.feed.tags && this.props.feed.tags.map((tag) => (
                                <Chip key={tag} label={tag} style={{marginRight: "4px"}}/>
                            ))}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        )
    }

    onFiltersClose = () => {
        this.setState({
            filtersOpen: false
        });
    };

    onFiltersOpen = () => {
        this.setState({
            filtersOpen: true
        });
    };

    setTags = (tags) => {
        this.props.onFeedChanged(this.props.index, {
            "rssUrl": this.props.feed.rssUrl,
            "tags": tags,
            "urlValid": this.validateURL(this.props.feed.rssUrl)
        })
    }

    handleURLChange = (event) => {
        this.props.onFeedChanged(this.props.index, {
            "rssUrl": event.target.value,
            "tags": this.props.feed.tags,
            "urlValid": this.validateURL(event.target.value)
        })
    }

    validateURL = (textval) => {
        var urlregex = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2,}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&:%$#=~_-]+))*$/;
        return urlregex.test(textval);
    }
}

export default withStyles(styles)(RSSFeed);