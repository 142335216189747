import React from 'react';

import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';

import Button from '@material-ui/core/Button';

class ClipShareDialog extends React.Component {

    render() {
        let link = "clipca.st/"+this.props.signature;
        return (
            <div>
                <Dialog fullWidth={true} onClose={this.props.onClose} aria-labelledby="share-clip" open={this.props.open}>
                    <DialogContent>
                        <p>
                            You can share this clip with the link <a rel="noopener noreferrer" target="_blank" href={"https://"+link}>{link}</a>
                        </p>
                        {!this.props.hideClipboardButton && <Button variant="contained" color="primary" onClick={this.copyToClipboard}>
                            Copy to Clipboard
                        </Button>}
                        <input style={{position: "absolute", left: "-999999px"}} id="shareLinkCopy" defaultValue={link}></input>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }

    copyToClipboard = () => {
        let textArea= document.getElementById("shareLinkCopy");
        textArea.select();
        document.execCommand('copy');
    }
}

export default ClipShareDialog;