import React, { Component } from 'react';

import Typography from '@material-ui/core/Typography';

class FourOFour extends Component {
    render() {
        return (
            <div>
                <div style={{marginTop: "20px"}}>
                    <Typography variant="h5" component="p" style={{color: "rgb(120, 120, 120)"}}>
                        404: Not Found
                    </Typography>
                    <Typography component="p" style={{color: "rgb(120, 120, 120)"}}>
                        <i>The page you are looking for was not found. </i>
                    </Typography>
                </div>
            </div>
        )
    }
}

export default FourOFour;