import React, { Component } from "react";

// MATERIAL-UI
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// ICONS
import { ExpandMore, LibraryBooks } from '@material-ui/icons';
// Fetch
import AppStore from '../../fetch/appStore'
// COMPONENTS
import TermsOfService from './TermsOfService'

const styles = theme => ({
    stripeBadge: {
        align: "center",
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2
    }
});

class StepThree extends Component {
    state = {
        tosAccepted: false
    };

    render() {

        const { classes } = this.props;

        return (
            <div>
                <Grid
                    container
                    spacing={24}
                    direction="row"
                    justify="center"
                    alignItems="center"
                >
                    <Grid item xs={8} align='center'>
                        <Typography variant={'body1'}>
                            Once you accept our Terms of Service, you can securely enter your payment information
                            through our portal. When this step is completed, you will be taken to the podcaster
                            dashboard where you can manage your account and podcasts.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} align='center'>
                        <ExpansionPanel>
                            <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                                <LibraryBooks/><Typography style={{marginLeft: "10px"}}> Terms of Service</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <TermsOfService/>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Grid>
                    <Grid item xs={12} align='center'>
                        <FormControlLabel control={<Checkbox checked={this.state.tosAccepted}
                                onClick={this.onAcceptTos}/>}
                                label="I accept the terms of service" />
                        <br/>
                        <Button
                            data-cb-type="checkout"
                            data-cb-plan-id="clipcast"
                            variant="contained"
                            color="primary"
                            disabled={!this.state.tosAccepted}>
                                Enter Payment Information
                        </Button>
                        <br/>
                        <div className={classes.stripeBadge}>
                            <img alt="" align="center" src={process.env.PUBLIC_URL+'/stripe_badge.svg'} height="25px" ></img>
                        </div>
                    </Grid>
                </Grid>
            </div>
        )
    }

    componentDidMount () {
        // this is required to force the Chargbee script to re-attach to the button
        // https://www.chargebee.com/checkout-portal-docs/drop-in-tutorial.html#implementation
        window.Chargebee.registerAgain();

        // these lines automatically fill in the checkout portal with the email address from Clipcast
        let userObj = AppStore.fetchUserInfo();

        var cbInstance = window.Chargebee.getInstance();
        var cart = cbInstance.getCart();
        cart.setCustomer({"email": userObj.email});
    }

    onAcceptTos = () => {
        this.setState({tosAccepted: !this.state.tosAccepted});
        this.props.onPaymentClicked();
    }
}


export default withStyles(styles)(StepThree);