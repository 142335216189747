/**
 * Configuration parameters for the app
 */
var AppConfig = {

    /**
     * The URL for all calls
     */
    apiUrl:  process.env.REACT_APP_ENV === "prod" ? 'https://api.clipcast.app' : 'https://test-api.clipcast.app',

    /**
     * display errors in the console
     */
    enableShowErrorLog: process.env.REACT_APP_ENV !== "prod",

    /**
     * The feature toggle for showing signups
     */
    enableSignup: true,

    /**
     * The feature toggle for multiple playlists
     */
    enableManyPlaylists: true,

};

export default AppConfig