import React, { Component, Fragment } from "react";
import PropTypes from 'prop-types';
// ROUTER
import { Link, withRouter } from "react-router-dom";
// MATERIAL-UI
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
// Icons
import ChevronLeft from "@material-ui/icons/ChevronLeft";
// ROUTES
import { HelpRoutes, PodcasterRoutes, UserRoutes, NonPodcasterRoutes } from "../../routes/routes";
// LIBS
import AppConfig from '../../appConfig';
// COMPONENTS
import PlaylistsNavigation from './PlaylistsNavigation';
// CONSTANTS
import { LOGGED_IN_STATE } from "../../constants";

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: "flex"
  },
  grow: {
      flexGrow: 1,
  },
  betaText: {
    color: '#888',
    fontSize: '12px',
    marginBottom: '4px'
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  drawerMessage: {
    padding: theme.spacing.unit,
    color: '#666666',
    fontStyle: 'italic'
  },
  menuButton: {
    marginRight: theme.spacing.unit,
    [theme.breakpoints.down('sm')]: {
      //display: 'none',
    },
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  },
  drawerPaper: {
    width: drawerWidth
  }
});

class NavigationDrawer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: props.open
    };
  }

  render() {
    const { classes, handleDrawerClose, loggedInState, open, userInfo, userPodcasts } = this.props;
    
    let user_routes = UserRoutes.concat( userPodcasts || userInfo.is_admin ? PodcasterRoutes : NonPodcasterRoutes );

    const drawer = (
      <Fragment>
        <div className={classes.drawerHeader}>
          <IconButton 
            onClick={handleDrawerClose} 
            className={classes.menuButton}
          >
            <ChevronLeft />
          </IconButton>
        </div>
        <Divider />
        {/* Routes for an ordinary logged in user */}
        <List>
          {user_routes.map((prop, key) => {
            if (
              (!prop.requiresLogin ||
              loggedInState === LOGGED_IN_STATE.LOGGED_IN)
              &&
              (!prop.featureToggled || prop.featureToggleValue)
            ) {
              return (
                <Link
                  to={prop.path}
                  style={{ textDecoration: "none" }}
                  key={key}
                  onClick={handleDrawerClose}
                >
                  <ListItem button key={"home"}>
                    <ListItemIcon>
                      <prop.icon />
                    </ListItemIcon>
                    <ListItemText primary={prop.navbarName} />
                    {prop.beta &&
                      <p className={classes.betaText}>Beta</p>
                    }
                  </ListItem>
                </Link>
              );
            }
            return null;
          })}
        </List>
        <Divider />
        {/* Playlist routes */}
        {AppConfig.enableManyPlaylists &&
          loggedInState === LOGGED_IN_STATE.LOGGED_IN &&
          <PlaylistsNavigation userInfo={userInfo} handleDrawerClose={handleDrawerClose} />
        }
        <Divider />
        {loggedInState === LOGGED_IN_STATE.LOGGED_OUT &&
          <Fragment>
            <Typography variant={'body2'} className={classes.drawerMessage} >
              Login or create an account to access additional features.
            </Typography>
            <Divider />
          </Fragment>
        }
        {/* Podcaster routes
        <List>
          {PodcasterRoutes.map((prop, key) => {
            if (
              (!prop.requiresLogin ||
              loggedInState === LOGGED_IN_STATE.LOGGED_IN)
              &&
              (!prop.featureToggled || prop.featureToggleValue)
            ) {
              return (
                <Link
                  to={prop.path}
                  style={{ textDecoration: "none" }}
                  key={key}
                  onClick={handleDrawerClose}
                >
                  <ListItem button key={"home"}>
                    <ListItemIcon>
                      <prop.icon />
                    </ListItemIcon>
                    <ListItemText primary={prop.navbarName} />
                    {prop.beta &&
                      <p className={classes.betaText}>Beta</p>
                    }
                  </ListItem>
                </Link>
              );
            }
            return null;
          })}
        </List> */}
        <div className={classes.grow} />
        <List>
          {HelpRoutes.map((prop, key) => {
            return (
              <Link
                to={prop.path}
                style={{ textDecoration: "none" }}
                key={key}
                onClick={handleDrawerClose}
              >
                <ListItem button key={"home"}>
                  <ListItemIcon>
                    <prop.icon />
                  </ListItemIcon>
                  <ListItemText primary={prop.navbarName} />
                </ListItem>
              </Link>
            );
          })}
        </List>
      </Fragment>
    );

    return (
      <nav>
        <Hidden smUp implementation="css">
          <Drawer
            className={classes.drawer}
            variant="temporary"
            anchor="left"
            open={open}
            onClose={handleDrawerClose}
            classes={{
              paper: classes.drawerPaper
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            onClose={handleDrawerClose}
            classes={{
              paper: classes.drawerPaper
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    );
  }
}

NavigationDrawer.propTypes = {
  handleDrawerClose: PropTypes.func.isRequired, 
  loggedInState: PropTypes.number.isRequired, 
  open: PropTypes.bool.isRequired,
  userInfo: PropTypes.object.isRequired,
}
export default withStyles(styles)(withRouter(NavigationDrawer));
