import React, { Component } from "react";

// MATERIAL-UI
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
    tos: {
        textAlign: "left",
        margin: theme.spacing.unit * 2
    }
});

class PrivacyPolicy extends Component {
    render() {

        const { classes } = this.props;

        return (
            <Grid
                spacing={24}
                direction="column"
                justify="left"
                alignItems="left"
                className={classes.tos}
            >
                <Typography variant={'h5'}>
                    Privacy Policy
                </Typography>
                <br/>
                <Typography variant={'p'}>
                    This privacy notice discloses the privacy practices for Clipcast, including but no limited to any
                    website or mobile app branded as such.
                    This privacy notice applies solely to information collected by these websites and apps. It will
                    notify you of the following:
                    <ul>
                        <li>
                            What personally identifiable information is collected from you through the website, how it
                            is used and with whom it may be shared.
                        </li>
                        <li>
                            What choices are available to you regarding the use of your data.
                        </li>
                        <li>
                            The security procedures in place to protect the misuse of your information.
                        </li>
                        <li>
                            How you can correct any inaccuracies in the information.
                        </li>
                    </ul>
                </Typography>

                <br/>
                <Typography variant={'p'}>
                    <b>Information Collection, Use, and Sharing </b>
                </Typography>
                <br/>
                <Typography variant={'p'}>
                    We are the sole owners of the information collected on this site. We only have access to/collect
                    information that you voluntarily give us via email or other direct contact from you. We will not
                    sell or rent this information to anyone.
                </Typography>

                <br/>
                <Typography variant={'p'}>
                    We will use your information to respond to you, regarding the reason you contacted us. We will not
                    share your information with any third party outside of our organization, other than as necessary to
                    fulfill your request.
                </Typography>

                <br/>
                <Typography variant={'p'}>
                    Unless you ask us not to, we may contact you via email in the future to tell you about specials,
                    new products or services, or changes to this privacy policy.
                </Typography>

                <br/>
                <Typography variant={'p'}>
                    <b>Your Access to and Control Over Information </b>
                </Typography>
                <br/>
                <Typography variant={'p'}>
                    You may opt out of any future contacts from us at any time. You can do the following at any time by
                    contacting us via the email address or phone number given on our website:
                    <ul>
                        <li>
                            See what data we have about you, if any.
                        </li>
                        <li>
                            Change/correct any data we have about you.
                        </li>
                        <li>
                            Have us delete any data we have about you.
                        </li>
                        <li>
                            Express any concern you have about our use of your data.
                        </li>
                    </ul>

                </Typography>

                <br/>
                <Typography variant={'p'}>
                    <b>Security</b>
                </Typography>
                <br/>
                <Typography variant={'p'}>
                    We take precautions to protect your information. When you submit sensitive information via the
                    website, your information is protected both online and offline.
                </Typography>
                <br/>
                <Typography variant={'p'}>
                    Wherever we collect sensitive information (such as credit card data), that information is encrypted
                    and transmitted to us in a secure way. You can verify this by looking for a lock icon in the
                    address bar and looking for "https" at the beginning of the address of the Web page.
                </Typography>
                <br/>
                <Typography variant={'p'}>
                    While we use encryption to protect sensitive information transmitted online, we also protect your
                    information offline. Only employees who need the information to perform a specific job (for example,
                    billing or customer service) are granted access to personally identifiable information. The
                    computers/servers in which we store personally identifiable information are kept in a secure
                    environment.
                </Typography>

                <br/>
                <Typography variant={'p'}>
                    <b>Registration </b>
                </Typography>
                <br/>
                <Typography variant={'p'}>
                    In order to use certain features of Clipcast, a user must first complete the registration form.
                    During registration a user is required to give certain information (such as name and email address).
                    This information is used to contact you about the products/services on our site in which you have
                    expressed interest. At your option, you may also provide demographic information (such as gender or
                    age) about yourself, but it is not required.
                </Typography>

                <br/>
                <Typography variant={'p'}>
                    <b>Cookies</b>
                </Typography>
                <br/>
                <Typography variant={'p'}>
                    We use "cookies" on this site. A cookie is a piece of data stored on a site visitor's hard drive to
                    help us improve your access to our site and identify repeat visitors to our site. For instance, when
                    we use a cookie to identify you, you would not have to log in a password more than once, thereby
                    saving time while on our site. Cookies can also enable us to track and target the interests of our
                    users to enhance the experience on our site. Usage of a cookie is in no way linked to any personally
                    identifiable information on our site.
                </Typography>

                <br/>
                <Typography variant={'p'}>
                    <b>Links</b>
                </Typography>
                <br/>
                <Typography variant={'p'}>
                    This website contains links to other sites. Please be aware that we are not responsible for the
                    content or privacy practices of such other sites. We encourage our users to be aware when they leave
                    our site and to read the privacy statements of any other site that collects personally identifiable
                    information.
                </Typography>

                <br/>
                <Typography variant={'p'}>
                    <b>If you feel that we are not abiding by this privacy policy, you should contact us immediately
                        via email at hello@clipcast.app.</b>
                </Typography>
                <br/>

            </Grid>
        );
    }
}

export default withStyles(styles)(PrivacyPolicy);