import React, { Component } from "react";

import Select from 'react-select';

// RECHARTS
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, BarChart, Bar } from 'recharts';

// MATERIAL-UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

// FETCH
import UserFetch from '../../fetch/userFetch';
import ClipsFetch from '../../fetch/clipsFetch';

const styles = theme => ({
    root: {
        margin: theme.spacing.unit*5
    },
    centerText: {
        textAlign: "center"
    },
    toggleBar: {
        display: "inline-block"
    }
});

class DashboardAnalytics extends Component {

    state = {
        loading: true,
        eventAnalytics: null,
        podcasts: [],
        selectedOption: null,
        days: "30"
    };

    render() {

        const { classes } = this.props;

        let options = this.state.podcasts.map((podcast) => {
            return {
                value: podcast['name'],
                label: podcast['title']
            }
        });

        const waitingHeight = 60;
        const waitingTop = (window.innerHeight/2)-(waitingHeight/2);
        const waitingLeft = (window.innerWidth/2)-(waitingHeight/2);

        return (
            <div>
                {this.state.loading && <CircularProgress
                    size={waitingHeight}
                    style={{margin: "auto", position: 'absolute', left: waitingLeft, top: waitingTop, zIndex: 99}}
                />
                }

                <Grid container spacing={24} alignItems="center">
                    <Grid item xs={12} alignItems="center">
                        <div style={{marginTop: "40px", marginBottom: "40px"}}>
                            <Select
                                value={this.state.selectedOption}
                                onChange={this.onPodcastSelected}
                                options={options}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        {this.state.eventAnalytics &&
                            <div>
                                <ToggleButtonGroup
                                    className={classes.toggleBar}
                                    value={this.state.days}
                                    exclusive
                                    onChange={(event, days) => {this.setState({days: days, loading: true}, this.loadAnalytics)}}>
                                    <ToggleButton value="10">
                                        10 Days
                                    </ToggleButton>
                                    <ToggleButton value="30">
                                        30 Days
                                    </ToggleButton>
                                    <ToggleButton value="60">
                                        60 Days
                                    </ToggleButton>
                                    <ToggleButton value="90">
                                        90 Days
                                    </ToggleButton>
                                </ToggleButtonGroup>
                                <br/>
                                <br/>
                                <Typography component={'p'}>
                                    <b>Plays per Day</b>
                                </Typography>
                                <br/>
                                <Typography component={'p'}>
                                    <i>The number of times someone played a clip of your podcast,
                                        both per day and cumulatively over the course of 30 days.</i>
                                </Typography>
                                <br/>
                                <ResponsiveContainer width="100%" height={300}>
                                    <LineChart data={this.state.eventAnalytics.plays_by_day}>
                                        <Line type="monotone" dataKey="plays" stroke="#005b9f"/>
                                        <Line type="monotone" dataKey="cumulative" stroke="#5eb8ff"/>
                                        <CartesianGrid stroke="#ccc" />
                                        <XAxis dataKey="date" />
                                        <YAxis />
                                        <Tooltip />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        {this.state.eventAnalytics &&
                            <div>
                                <Typography component={'p'}>
                                    <b>Plays by Keyword</b>
                                </Typography>
                                <br/>
                                <Typography component={'p'}>
                                    <i>The top 10 keywords searched that resulted in a play click.</i>
                                </Typography>
                                <br/>
                                <ResponsiveContainer width="100%" height={300}>
                                    <BarChart data={this.state.eventAnalytics.plays_by_keyword.slice(0,10)}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="keyword" />
                                        <YAxis />
                                        <Tooltip />
                                        <Bar dataKey="plays" fill="#ff9800" />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        {this.state.eventAnalytics &&
                        <div>
                            <Typography component={'p'}>
                                <b>Plays by Episode</b>
                            </Typography>
                            <br/>
                            <Typography component={'p'}>
                                <i>The total play clicks per episode of your podcast.</i>
                            </Typography>
                            <br/>
                            <ResponsiveContainer width="100%" height={300}>
                                <BarChart data={this.state.eventAnalytics.plays_by_episode}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="episode" />
                                    <YAxis />
                                    <Tooltip />
                                    <Bar dataKey="plays" fill="#ff9800" />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                        }
                    </Grid>
                </Grid>
            </div>
        )
    }

    componentDidMount() {
        this.fetchPodcasts();
    }

    fetchPodcasts = () => {
        UserFetch.getUserInfo()
            .then(user => {
                // if admin, we can get all podcasts
                let podcastFunction = user.is_admin ? ClipsFetch.fetchPodcasts : UserFetch.getPodcasts;

                podcastFunction().then((podcasts) => {
                    let firstOption = {
                        value: podcasts[0]['name'],
                        label: podcasts[0]['title']
                    }

                    this.setState({podcasts: podcasts, loading: false}, () => {this.onPodcastSelected(firstOption)})
                }).catch((error) => {
                    console.log('unable to fetch list')
                    this.setState({loading: false});
                });
            });
    };

    onPodcastSelected = (selectedEpisode) => {

        this.setState(
            {
                selectedOption: selectedEpisode,
                loading: true,
            }, this.loadAnalytics
        );
    };

    loadAnalytics = () => {
        UserFetch.getPodcastEventAnalytics(this.state.selectedOption.value, this.state.days)
        .then(eventAnalytics => {
            this.setState({eventAnalytics: eventAnalytics, loading: false});
        });
    }
}


export default withStyles(styles)(DashboardAnalytics);