import React, { Component } from "react";

// MATERIAL-UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
// FETCH
import UserFetch from '../../fetch/userFetch';
import AppStore from '../../fetch/appStore';
// Components
import Podcast from '../common/Podcast';
import RSSFeed from '../common/RSSFeed';

const styles = theme => ({
    root: {
        margin: theme.spacing.unit*5,
        align: "center"
    },
    card: {
        margin: theme.spacing.unit*2,
        align: "center"
    }
});

class ManagePodcasts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            podcasts: null,
            deleteDialogOpen: false,
            podcastToDelete: null,
            feed: this.getBlankFeed(),
            loading: true
        };
    }

    render() {
        const waitingHeight = 60;
        const waitingTop = (window.innerHeight/2)-(waitingHeight/2);
        const waitingLeft = (window.innerWidth/2)-(waitingHeight/2);

        if (this.state.podcasts === null) {
            return (
                <CircularProgress
                    size={waitingHeight}
                    style={{margin: "auto", position: 'absolute', left: waitingLeft, top: waitingTop, zIndex: 99}}
                />
            );
        }

        const { podcasts, feed } = this.state;
        const { classes } = this.props;

        return (
            <div className={classes.root} align='center'>

                <Typography component={'p'}>
                    <b>Your Podcasts</b>
                </Typography>
                <Grid container spacing={24} justify="center" direction="row">
                    {podcasts.map((podcast, key) => (
                        <div className={classes.card} key={key}>
                            <Podcast podcast={podcast} onRemoved={this.onRemovePodcast}/>
                        </div>
                    ))}
                </Grid>

                <br/>
                <Typography component={'p'}>
                    <b>Add New Podcasts</b>
                </Typography>
                <RSSFeed
                    feed={feed}
                    onFeedChanged={this.onNewFeedChange}
                />
                <Button
                    variant="contained"
                    color="primary"
                    disabled={!this.state.feed.urlValid || this.state.loading}
                    onClick={this.onAddPodcast}>
                        Add Podcast
                </Button>


                <Dialog
                    open={this.state.deleteDialogOpen}
                    onClose={() => {this.setState({deleteDialogOpen: false})}}
                >
                    <DialogTitle>{"Delete podcast from your account?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            If you delete this podcast from your account, it will no longer be searchable on Clipcast
                            and you will no longer have access to analytics data for it.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {this.setState({deleteDialogOpen: false})}} color="primary">
                            Disagree
                        </Button>
                        <Button onClick={this.onDeletePodcast} color="primary" autoFocus>
                            Agree
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    componentDidMount() {
        this.fetchPodcasts();
    }

    fetchPodcasts = () => {
        UserFetch.getUserInfo()
        .then(user => {
            AppStore.storeUserInfo(user);
            UserFetch.getPodcasts().then((podcasts) => {
                this.setState({podcasts: podcasts, loading: false})
            }).catch((error) => {
                console.log('unable to fetch list')
                this.setState({loading: false});
            });
        });
    };

    onRemovePodcast = (podcast) => {
        this.setState({
            deleteDialogOpen: true,
            podcastToDelete: podcast
        })
    };

    onAddPodcast = () => {
        this.setState({loading: true}, () => {
            UserFetch.putPodcast(this.state.feed).then(() => {
                this.fetchPodcasts();
            }).catch((error) => {
                console.log('unable to fetch list')
                this.setState({loading: false});
            });
        });
    };

    onDeletePodcast = () => {
        UserFetch.deletePodcast(this.state.podcastToDelete.name).then(() => {
            this.setState({deleteDialogOpen: false}, () => {this.fetchPodcasts()});
        }).catch((error) => {
            console.log('unable to delete')
            this.setState({loading: false});
        });
    }

    onNewFeedChange = (key, feed) => {
        this.setState({feed: feed});
    };

    getBlankFeed = () => {
        return {
            rssUrl: "",
            tags: [],
            urlValid: false
        }
    };
}


export default withStyles(styles)(ManagePodcasts);