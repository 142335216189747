import React from "react";
import PropTypes from "prop-types";
// MATERIAL-UI
import Fab from '@material-ui/core/Fab';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// FETCH
import ClipsFetchs from "../../fetch/clipsFetch";
// ICONS
import PlayArrow from "@material-ui/icons/PlayArrow";
import PauseSharp from "@material-ui/icons/PauseSharp";

// of source, we need something special for iOS
const IOS =
  typeof navigator !== "undefined" &&
  /iPad|iPhone|iPod/.test(navigator.userAgent) &&
  !window.MSStream;

class ProgressBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      innerId: Math.random()
        .toString(36)
        .substr(2, 10),
      outerId: Math.random()
        .toString(36)
        .substr(2, 10)
    };
  }

  render() {
    let pbStyles = {
      progressBar: {
        height: 8,
        borderRadius: "22px",
        background: "rgb(235, 235, 255)"
      },
      innerBar: {
        height: 8,
        borderRadius: "22px",
        background: "rgb(20, 20, 255)",
        width: this.props.percentFull * 100 + "%"
      }
    };

    return (
      <div
        style={pbStyles.progressBar}
        onClick={this.onClick}
        id={this.state.outerId}
      >
        <div style={pbStyles.innerBar} id={this.state.innerId} />
      </div>
    );
  }

  onClick = evt => {
    let outerBar = document.getElementById(this.state.outerId);
    let width =
      outerBar.getBoundingClientRect().right -
      outerBar.getBoundingClientRect().left;
    let leftStart = outerBar.getBoundingClientRect().left;
    if (this.props.onSeekChange) {
      this.props.onSeekChange((evt.clientX - leftStart) / width);
    }
  };
}

class WavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      svgId: Math.random()
        .toString(36)
        .substr(2, 10),
      editX: 0,
      editWidth: 300
    };
  }

  render() {
    let pbStyles = {
      progressBar: {
        fill: "#ff9800"
      }
    };

    let progressX = 300.0 * this.props.percentFull;
    let bucketWidth = 300.0 / this.props.waveform.length;
    var key = 0;
    return (
      <div>
        <svg viewBox="0 0 300 100" onClick={this.onClick} id={this.state.svgId}>
          {this.props.waveform.map(bar => (
            <rect
              style={pbStyles.progressBar}
              x={bucketWidth * key + 0.5 / 2}
              y={100.0 - (bar * 100.0) / 2}
              width={bucketWidth - 0.5}
              height={100.0 * bar}
              rx="2"
              ry="2"
              key={key++}
            />
          ))}
          <rect width={1} height={200} y={0} x={progressX} />
          {this.props.editMode && (
            <rect
              width={this.state.editWidth}
              height={200}
              y={0}
              x={this.state.editX}
              style={{ fillOpacity: 0.1 }}
            />
          )}
        </svg>
      </div>
    );
  }

  onClick = evt => {
    let outerBar = document.getElementById(this.state.svgId);
    let width =
      outerBar.getBoundingClientRect().right -
      outerBar.getBoundingClientRect().left;
    let leftStart = outerBar.getBoundingClientRect().left;
    if (this.props.onSeekChange) {
      this.props.onSeekChange((evt.clientX - leftStart) / width);
    }
  };
}

class ClipPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      player: null,
      percentFull: 0,
      playing: false,
      mediaReady: false,
      playedOnce: false
    };
  }

  render() {
    if (!this.props) {
      return null;
    }

    const { endTime, progressBar } = this.props;

    let width = this.props.width ? this.props.width : "100%";
    const ButtonIcon = this.state.playing ? PauseSharp : PlayArrow;

    var minutes = this.state.player
      ? Math.floor(this.state.player.currentTime / 60)
      : null;
    var seconds = this.state.player
      ? this.pad(Math.round(this.state.player.currentTime - minutes * 60), 2)
      : null;

    if (seconds === "60") {
      seconds = "00";
      minutes += 1;
    }

    if (this.props.startPlaying) {
      this.onPlayPause();
    }

    // soundcloud has some funny redirect system that can time out the URL after a few minutes. this is necessary to
    // load the entire file up front. it is horribly inefficient, but the only way to make it work
    let autoPlayMode = this.props.clip.url.includes("soundcloud.com") ? "auto" : "metadata";

    return (
      <div>
        <audio
          id={this.props.clip.signature}
          src={this.props.clip.url}
          preload={autoPlayMode}
          style={{ width: width }}
        />
        <Grid container spacing={24} alignItems="center">
          <Grid item sm={progressBar ? 1 : 2} xs={2}>
            {!this.props.hideButton && (
              <div>
                {this.state.mediaReady && (
                  <Fab
                    variant="round"
                    color="primary"
                    aria-label="Add"
                    size='small'
                    onClick={this.onPlayPause}
                  >
                    <ButtonIcon />
                  </Fab>
                )}
                {!this.state.mediaReady && (
                  <Fab
                    variant="round"
                    color="primary"
                    aria-label="Add"
                    size='small'
                    disabled
                    onClick={this.onPlayPause}
                  >
                    <ButtonIcon />
                  </Fab>
                )}
              </div>
            )}
            {!this.props.hideButton && (
              <div>
                <br /> <br />
              </div>
            )}
            {this.state.player && (
              <Typography variant={"body2"}>
                <b>
                  {minutes}:{seconds}
                </b>
              </Typography>
            )}
          </Grid>
          <Grid item sm={progressBar ? 10 : 8} xs={8}>
            {progressBar && (
              <ProgressBar
                percentFull={this.state.percentFull}
                onSeekChange={this.onSeekChange}
              />
            )}
            {!this.props.progressBar && (
              <WavBar
                waveform={this.props.clip.waveform}
                percentFull={this.state.percentFull}
                onSeekChange={this.onSeekChange}
                editMode={this.props.editMode}
              />
            )}
          </Grid>
          <Grid item sm={progressBar ? 1 : 2} xs={2}>
            <Typography variant={"body2"}>
              <b>{endTime}</b>
            </Typography>
          </Grid>
        </Grid>
      </div>
    );
  }

  componentDidMount() {
    this.setState(
      { player: document.getElementById(this.props.clip.signature) },
      () => {
        if (IOS) {
          this.state.player.load();
        }
        this.attachListeners();
      }
    );
  }

  componentWillUnmount() {
    this.detachListeners();
  }

  attachListeners = () => {
    this.state.player.addEventListener("canplay", this.onReady);
    this.state.player.addEventListener("timeupdate", this.onProgress);
  };

  detachListeners = () => {
    this.state.player.removeEventListener("timeupdate", this.onProgress);
  };

  onPlayPause = () => {
    if (!this.state.playing && !this.state.playedOnce) {
      // if we're not playing, then we are about to be. log a play event
      ClipsFetchs.postClipPlayEvent(this.props.clip)
        .then(response => {
          this.setState({ playedOnce: true });
        })
        .catch(error => {});
    }
    this.setState({ playing: !this.state.playing }, () => {
      if (this.state.playing) {
        this.state.player.play();
      } else {
        this.state.player.pause();
      }
    });
  };

  onSeekChange = percent => {
    // eslint-disable-next-line
    this.state.player.currentTime =
      this.props.clip.start_time +
      (this.props.clip.end_time - this.props.clip.start_time) * percent;
    this.onProgress();
  };

  onReady = () => {
    if (this.props.audioDuratoinCallback) {
      this.props.audioDuratoinCallback(this.state.player.duration);
    }
    // eslint-disable-next-line
    this.state.player.currentTime = this.props.clip.start_time;
    this.state.player.removeEventListener("canplay", this.onReady);
    this.setState({ mediaReady: true });
  };

  onProgress = () => {
    var playing = this.state.playing;
    if (
      Math.round(this.state.player.currentTime) >=
      Math.round(this.props.clip.end_time)
    ) {
      this.state.player.pause();
      // eslint-disable-next-line
      this.state.player.currentTime = this.props.clip.start_time;
      playing = false;
      if (this.props.playEndedCallback) {
        this.props.playEndedCallback();
      }
    }
    let percentFull =
      (this.state.player.currentTime - this.props.clip.start_time) /
      (this.props.clip.end_time - this.props.clip.start_time);
    if (
      percentFull !== this.state.percentFull ||
      playing !== this.state.playing
    ) {
      this.setState({
        mediaReady: true,
        playing: playing,
        percentFull: percentFull
      });
    }
  };

  pad = (num, size) => {
    var s = String(num);
    while (s.length < (size || 2)) {
      s = "0" + s;
    }
    return s;
  };
}

ClipPlayer.propTypes = {
  endTime: PropTypes.string
};

ClipPlayer.defaultProps = {
  endTime: ""
};
export default ClipPlayer;
