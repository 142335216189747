import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
// MATERIAL-UI
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// ICONS
import Headset from '@material-ui/icons/Headset';
import MoreHoriz  from '@material-ui/icons/MoreHoriz';
import PlaylistPlayOutlined  from '@material-ui/icons/PlaylistPlayOutlined';

class PlaylistsNavigation extends React.Component {
  state = {
    showAll: false,
  }

  render() {
    const { props, state } = this;

    if(!props || !props.userInfo) {
      return null
    }

    const { showAll } = state;
    const { handleDrawerClose, userInfo} = props;
    const { queues } = userInfo

    if(!queues) {
      return null
    }

    const filteredQueues = queues.filter((q) => {
      return q !== 'new'; // filter out the 'new' queue
    })

    let queuesToRender = filteredQueues;
    let showMoreLink = false;
    let showLessLink = false;

    if(!showAll && filteredQueues.length > 3) {
      queuesToRender = filteredQueues.slice(0,3);
      showMoreLink = true;
    }
    if(showAll && filteredQueues.length > 3) {
      showLessLink = true;
    }
 
    return (
      <List>
        <Link
          to={`/user/playlists/new`}
          style={{ textDecoration: "none" }}
          key={'1234563'}
          onClick={handleDrawerClose}
        >
          <ListItem button key={"listenLater"}>
            <ListItemIcon>
              <Headset />
            </ListItemIcon>
            <ListItemText primary='Listen later' />
          </ListItem>
        </Link>
        {queuesToRender.map((prop, key) => {
          return (
            <Link
              to={`/user/playlists/${prop}`}
              style={{ textDecoration: "none" }}
              key={key}
              onClick={handleDrawerClose}
            >
              <ListItem button key={key}>
                <ListItemIcon>
                  <PlaylistPlayOutlined />
                </ListItemIcon>
                <ListItemText primary={prop} />
              </ListItem>
            </Link>
          );
        })}
        {showMoreLink && 
          <ListItem button key={"showMore"} onClick={this.onShowMore}>
            <ListItemIcon>
              <MoreHoriz />
            </ListItemIcon>
            <ListItemText 
              primary={'Show more'} 
              primaryTypographyProps={{variant:'caption', color: 'primary'}}
            />
          </ListItem>
        }
        {showLessLink && 
          <ListItem button key={"showMore"} onClick={this.onShowLess}>
            <ListItemIcon>
              <MoreHoriz />
            </ListItemIcon>
            <ListItemText 
              primary={'Show less'} 
              primaryTypographyProps={{variant:'caption', color: 'primary'}}
            />
          </ListItem>
        }
      </List>
    );
  }

  onShowMore = () => {
    this.setState({
      showAll: true,
    });
  }

  onShowLess = () => {
    this.setState({
      showAll: false,
    });
  }
}

PlaylistsNavigation.propTypes = {
  handleDrawerClose: PropTypes.func.isRequired,
  userInfo: PropTypes.object.isRequired,
}

export default PlaylistsNavigation;
