import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
// FETCH
import UserFetch from '../../fetch/userFetch'
// LIB
import URLDecode from '../../lib/URLDecode';
// MATERIAL-UI
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const styles = theme => ({
    card: {
        maxWidth: 800,
        margin: theme.spacing.unit * 2
    },
});

class ForgotPassword extends Component {

    state = {
        user_id: null,
        successMessage: null,
        errorMessage: null
    };

    render() {

        const { classes } = this.props;

        return (
            <Grid item xs={12} align='center'>
                <Card className={classes.card}>
                    <CardContent>
                        <Grid
                            container
                            direction="column"
                        >
                            <Typography style={{textAlign: "center", marginBottom: "4px", color: "rgb(120, 120, 120)"}}>
                                Are you sure you want to unsubscribe from Clipcast emails? You will no longer receive
                                suggested clips, industry statistics, or personalized content.
                            </Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={this.onSubmitClick}
                                style={{marginTop: "20px"}}
                            >
                                Unsubscribe
                            </Button>

                            <Typography style={{textAlign: "center", marginBottom: "4px"}}>
                                {this.state.successMessage}
                            </Typography>
                            <Typography style={{textAlign: "center", marginBottom: "4px", color: "rgb(255, 120, 120)"}}>
                                {this.state.errorMessage}
                            </Typography>

                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        );
    }

    onSubmitClick = () => {
        UserFetch.unsubscribeUser(this.state.user_id).then(() => {
            this.setState({successMessage: "You have successfully unsubscribed from Clipcast emails."})
        }).catch((error) => {
            this.setState({errorMessage: "Unable to unsubscribe. Please try again later."})
        });
    };

    componentDidMount() {
        let params = URLDecode.decodeObject(this.props.location.search);
        this.setState({
            user_id: params.user_id
        })
    };
}

export default withStyles(styles)(ForgotPassword);