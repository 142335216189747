import React, { Component, Fragment } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import classNames from "classnames";
// MATERIAL-UI
import CssBaseline from "@material-ui/core/CssBaseline";
import { withStyles } from "@material-ui/core/styles";
// ROUTES
import { Routes } from "./routes/routes";
// COMPONENTS
import FourOFour from "./components/fourOFour";
import Header from "./components/Header";
import NavigationDrawer from "./components/NavigationDrawer/NavigationDrawer";
import Footer from "./components/Footer";
import "./App.css";
// Constants
import { LOGGED_IN_STATE } from "./constants";
// FETCH
import UserFetch from "./fetch/userFetch";
import AppStore from "./fetch/appStore";

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: "flex"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    [theme.breakpoints.up("sm")]: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      marginLeft: 0
    }
  },
  contentShift: {
    [theme.breakpoints.up("sm")]: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: drawerWidth
    }
  }
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerOpen: false,
      loggedInState: LOGGED_IN_STATE.LOGGED_OUT,
      email: "",
      userInfo: {},
    };
  }

  render() {
    if (!this.props || !this.props.classes) {
      return null;
    }

    const { classes } = this.props;
    const { drawerOpen, loggedInState, email, userInfo, userPodcasts } = this.state;
    const routes = Routes.map((route, key) => {
      return (
        <Route
          key={key}
          path={route.path}
          //use the router render prop so we can pass the userInfo in props
          render={props => {
            return React.createElement(route.component, {
              ...props,
              userInfo: this.state.userInfo,
              getUserInfo: this.getUserInfo,
            });
          }}
          exact={route.exact}
          strict={route.strict}
        />
      );
    });
    
    return (
      <div className={classes.root}>
        <CssBaseline />

        <div style={{ width: "100%" }}>
          <Header
            drawerOpen={drawerOpen}
            loggedInState={loggedInState}
            logout={this.logout}
            openNavigationDrawer={this.openNavigationDrawer}
            email={email}
            getUserInfo={this.getUserInfo}
          />
          <Router>
            <Fragment>
              <NavigationDrawer
                handleDrawerClose={() => this.setState({ drawerOpen: false })}
                open={this.state.drawerOpen}
                loggedInState={loggedInState}
                userInfo={userInfo}
                userPodcasts={userPodcasts}
              />

              <main
                className={classNames(classes.content, {
                  [classes.contentShift]: drawerOpen
                })}
              >
                <Switch>
                  {routes}
                  <Route component={FourOFour} />
                </Switch>
                <Footer />
              </main>
            </Fragment>
          </Router>
        </div>
      </div>
    );
  }

  /**
   * Lifecycle methods
   */
  componentWillMount() {
    this.getUserInfo();
  }

  /**
   * CLass methods
   */
  getUserInfo = () => {
    UserFetch.getUserInfo()
    .then(user => {
        this.setState({
            loggedInState: user.loggedIn ?  LOGGED_IN_STATE.LOGGED_IN : LOGGED_IN_STATE.LOGGED_OUT,
            email: user.loggedIn? user.email : '',
            userPodcasts: user.loggedIn ? user.podcasts : null,
            userInfo: user,
        });
        AppStore.storeUserInfo(user);
    });
  };

  logout = () => {
    UserFetch.userLogout(this.state.email, this.state.password)
      .then(() => {
        this.getUserInfo();
        this.setState({ userMenuAnchor: null });
        window.location = "/";
      })
      .catch(error => {
        this.setState({
          errorMessage:
            "Unable to log in. Check email and password and try again."
        });
      });
  };
  openNavigationDrawer = () => {
    this.setState({
      drawerOpen: true
    });
  };
}

export default withStyles(styles)(App);
