import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// MATERIAL-UI
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from "@material-ui/core/Snackbar";
import Tooltip from "@material-ui/core/Tooltip";
// Constants
import { LOGGED_IN_STATE } from '../../constants';
// ICONS
import MenuIcon from '@material-ui/icons/Menu';
import Person from '@material-ui/icons/Person';
import CloseIcon from "@material-ui/icons/Close";
// COMPONENTS
import UserDialog from './userDialog';
// FETCH
import AppStore from '../../fetch/appStore'
// LIB
import PageState from './pageState';

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  grow: {
      flexGrow: 1,
  },
  appBar: {
      [theme.breakpoints.up('sm')]: {
          transition: theme.transitions.create(['margin', 'width'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
          }),  
      }
  },
  appBarShift: {
      [theme.breakpoints.up('sm')]: { 
          width: `calc(100% - ${drawerWidth}px)`,
          marginLeft: drawerWidth,
          transition: theme.transitions.create(['margin', 'width'], {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen,
          }),
      }
  },
  button: {
    margin: theme.spacing.unit,
  },
  signUpButton: {
    color: "rgb(255,255,255)"
  }
});

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state={
          userDialogOpen: false,
          userMenuAnchor: null,
          pageState: PageState.UNKNOWN,
          promptCreateAccountOpen: false,
      };
    }

    render() {

        if (!this.props) {
          return null;
        }

        const { 
          classes,
          drawerOpen,
          openNavigationDrawer,
          loggedInState,
          logout,
          email,
        } = this.props;
        
        let userName = null;
        if( loggedInState === LOGGED_IN_STATE.LOGGED_IN && email.length > 0 ) {
            const splitEmail = email.split('@');
            if(splitEmail.length > 0) {
                userName = splitEmail[0];
            }
        }
    
        return ( 
          <Fragment>
              <AppBar position="sticky" color="primary" 
                  className={classNames(classes.appBar, {
                    [classes.appBarShift]: drawerOpen,
                  })}>
                  <Toolbar disableGutters={!drawerOpen}>
                      <IconButton
                          color="inherit"
                          aria-label="Menu"
                          onClick={openNavigationDrawer}
                          >
                          <MenuIcon  />
                      </IconButton>
                      <div className={classes.grow}/>
                      {loggedInState === LOGGED_IN_STATE.LOGGED_OUT &&
                          <div>
                              <Button
                                  color="inherit"
                                  onClick={() => {this.setState({userDialogOpen: true, pageState: PageState.CREATE})}}
                                  >
                                      SIGN UP
                              </Button>
                              <Tooltip title="Login" placement="top">
                                  <IconButton
                                      key="login"
                                      aria-label="Login"
                                      color="inherit"
                                      onClick={() => {this.setState({userDialogOpen: true, pageState: PageState.LOGIN})}}
                                  >
                                      <Person />
                                  </IconButton>
                              </Tooltip>
                          </div>
                      }
                      {loggedInState === LOGGED_IN_STATE.LOGGED_IN &&
                          <Fragment>
                              <Button 
                                    color="inherit"
                                    size="small" 
                                    onClick={(event) => {this.setState({userMenuAnchor: event.currentTarget})}}
                                    className={classes.button}
                              >  
                                {!userName &&
                                  <Person />
                                }
                                {userName && 
                                  userName
                                }
                              </Button>
                          </Fragment>
                      }
                  </Toolbar>
              </AppBar>
              <UserDialog
                  open={this.state.userDialogOpen}
                  onClose={() => {this.setState({userDialogOpen: false}, this.props.getUserInfo)}}
                  pageState={this.state.pageState}
                  onForgetPasswordClicked={()=>{this.setState({pageState: this.state.pageState === PageState.LOGIN ? PageState.FORGOT : PageState.LOGIN})}}
                  />
              <Snackbar
                  open={this.state.promptCreateAccountOpen}
                  onClose={() => {this.setState({promptCreateAccountOpen: false})}}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left'}}
                  message={
                    <span style={{ textAlign: "center" }}>
                      <Button
                        className={classes.signUpButton}
                        onClick={() => {this.setState({promptCreateAccountOpen: false, userDialogOpen: true, pageState: PageState.CREATE})}}>
                        Create a free account for extra features
                      </Button>
                    </span>
                  }
                  action={[
                    <IconButton
                      key="close"
                      aria-label="Close"
                      color="inherit"
                      onClick={()=>{this.setState({promptCreateAccountOpen: false})}}
                    >
                      <CloseIcon />
                    </IconButton>
                  ]}
              />
              <Menu
                  anchorEl={this.state.userMenuAnchor}
                  open={this.state.userMenuAnchor !== null}
                  onClose={() => {this.setState({userMenuAnchor: null})}}
                >
                  <MenuItem onClick={logout}>Logout</MenuItem>
              </Menu>
          </Fragment>
        );
    }

    componentDidMount() {
        let userInfo = AppStore.fetchUserInfo();

        if ((userInfo === null || userInfo.email === null) && 
            (window.location.pathname === "/" || window.location.pathname.length === 13)) {
            setTimeout(() => {
                this.setState({promptCreateAccountOpen: true})
            }, 8000);
        }
    }

}

Header.propTypes = {
    drawerOpen: PropTypes.bool.isRequired,
    loggedInState: PropTypes.number,
    logout: PropTypes.func.isRequired,
    openNavigationDrawer: PropTypes.func.isRequired,
    email: PropTypes.string.isRequired,
}

Header.defaultProps = {
    loggedInState: LOGGED_IN_STATE.UNKNOWN,
}

export default withStyles(styles)(Header);
