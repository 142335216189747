import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
// FETCH
import UserFetch from '../../fetch/userFetch'
// LIB
import URLDecode from '../../lib/URLDecode';
import ValidEmail from '../../lib/validEmail';
// MATERIAL-UI
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const styles = theme => ({
    card: {
        maxWidth: 800,
        margin: theme.spacing.unit * 2
    },
});

class ForgotPassword extends Component {

    state = {
        email: "",
        password: "",
        code: "",
        successMessage: null,
        errorMessage: null
    };

    render() {

        let invalidEmail = !ValidEmail.validEmail(this.state.email);
        let invalidPassword = this.state.password.length < 5;

        const { classes } = this.props;

        return (
            <Grid item xs={12} align='center'>
                <Card className={classes.card}>
                    <CardContent>
                        <Grid
                            container
                            direction="column"
                            >
                            <Typography style={{textAlign: "center", marginBottom: "4px", color: "rgb(120, 120, 120)"}}>
                                Enter new password
                            </Typography>
                            <TextField
                                label="Email"
                                type="email"
                                margin="normal"
                                fullWidth
                                value={this.state.email}
                                onChange={(event)=>{this.setState({email: event.target.value})}}
                                onKeyPress={(ev) => {
                                    if (ev.key === 'Enter') {
                                      this.onSubmitClick();
                                    }
                                  }}
                            />
                            <TextField
                                label="Password"
                                type="password"
                                margin="normal"
                                fullWidth
                                value={this.state.password}
                                onChange={(event)=>{this.setState({password: event.target.value})}}
                                onKeyPress={(ev) => {
                                if (ev.key === 'Enter') {
                                  this.onSubmitClick();
                                }
                              }}
                            />
                            {!this.successMessage && <Button
                                variant="contained"
                                color="primary"
                                onClick={this.onSubmitClick}
                                style={{marginTop: "20px"}}
                                disabled={invalidEmail || invalidPassword}
                            >
                                Submit
                            </Button>
                            }

                            <Typography style={{textAlign: "center", marginBottom: "4px"}}>
                                {this.state.successMessage}
                            </Typography>
                            <Typography style={{textAlign: "center", marginBottom: "4px", color: "rgb(255, 120, 120)"}}>
                                {this.state.errorMessage}
                            </Typography>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        );
    }

    onSubmitClick = () => {
        UserFetch.forgotPasswordComplete(this.state.email, this.state.code, this.state.password).then(() => {
            this.setState({successMessage: "You have successfully changed your password. Please login to continue."})
            setInterval(() => {window.location = '/';}, 3000);
        }).catch((error) => {
            this.setState({errorMessage: "Unable to reset password. Check email and try again."})
        });
    };

    componentDidMount() {
        let params = URLDecode.decodeObject(this.props.location.search);
        this.setState({
            email: params.email,
            code: params.code
        })
    };
}

export default withStyles(styles)(ForgotPassword);