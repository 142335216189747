import React from 'react';
// ROUTER
import { Link } from 'react-router-dom';
// Material UI 
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
// COLOR
import grey from '@material-ui/core/colors/grey'

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
  paragraph: {
    color: grey[800],
    marginTop: theme.spacing.unit * 3, 
  }
});

const EmptyState = (props) => {
  if(!props) {
    return null;
  }

  const { classes } = props;

  return (
    <Grid 
        container 
        spacing={8}
        direction="row"
        justify="center"
        alignItems="center"
    >
        <Grid item xs={12} align='center'>
          <Typography variant={'body1'} className={classes.paragraph}>
            {`You can add clips to your playlist from the `}
            <Link to='/'>Home</Link> 
            {` screen.`}
          </Typography>
        </Grid>
    </Grid>
  );
}
 
export default withStyles(styles)(EmptyState);