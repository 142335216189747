import React, { Component } from "react";
import PropTypes from "prop-types";
// LIBS
import Moment from "react-moment";
// MATERIAL-UI
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
// REACT-BEAUTIFUL-DND
import { Draggable } from "react-beautiful-dnd";
// ICONS
import RemoveCircleOutlined from "@material-ui/icons/RemoveCircleOutline";
import DragIndicator from "@material-ui/icons/DragIndicatorOutlined";
// COLOR
import blue from "@material-ui/core/colors/blue";
import red from '@material-ui/core/colors/red';
// COMPONENTS
import ClipPlayer from "../common/clipPlayer";

const styles = theme => ({
  card: {
    marginLeft: theme.spacing.unit / 2,
    marginRight: theme.spacing.unit / 2,
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    textAlign: "left"
  },
  cardDragging: {
    marginLeft: theme.spacing.unit / 2,
    marginRight: theme.spacing.unit / 2,
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    textAlign: "left",
    backgroundColor: blue[100]
  },
  cardHeader: {
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    MozUserSelect:"none",
    WebkitUserSelect:"none",
    msUserSelect:"none" 
  },
  cardContent: {
    "&:last-child": {
      paddingTop: theme.spacing.unit,
      paddingBottom: theme.spacing.unit
    }
  },
  removeCircle: {
    color: red[800],
  },
  row: {
    padding: theme.spacing.unit / 2
  },
  unselectable: {
    MozUserSelect:"none",
    WebkitUserSelect:"none",
    msUserSelect:"none"
  }
});
class ClipQueueCard extends Component {
  render() {
    if (!this.props) {
      return null;
    }

    const { classes, clip, disabled, index } = this.props;

    const {
      end_time,
      entry_name,
      keywords,
      podcast_name,
      published,
      signature
    } = clip;

    let endMinutes = Math.floor(end_time / 60);
    let endSeconds = this.pad(Math.round(end_time - endMinutes * 60), 2);

    let publishedDate = Date.parse(published);

    let opacity = disabled ? "0.4" : "1";

    return (
      <Draggable draggableId={signature} index={index}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <Card
              className={
                snapshot.isDragging ? classes.cardDragging : classes.card
              }
            >
              <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                <div
                  style={{
                    minWidth: 32,
                    maxWidth: 32,
                    flexBasis: 1,
                    textAlign: 'center',
                  }}
                >
                  <Tooltip title="Drag to reorder" placement="top">
                    <DragIndicator color="primary"/>
                  </Tooltip>
                </div>
                <div style={{flexGrow:2}}>
                  <CardHeader
                    action={
                      <Tooltip title="Remove from Playlist" placement="top">
                        <IconButton
                          aria-label="Share"
                          onClick={this.onRemoveFromQueue}
                        >
                          <RemoveCircleOutlined className={classes.removeCircle} />
                        </IconButton>
                      </Tooltip>
                    }
                    title={podcast_name}
                    className={classes.cardHeader}
                  />
                  <CardContent className={classes.cardContent} style={{ opacity: opacity }}>
                    {/* <div > */}
                      <Grid container alignItems="center">
                        <Grid item xs={12} className={classes.row}>
                          <Typography noWrap={false} className={classes.unselectable}>{entry_name}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.row}>
                          <Typography color={"textSecondary"} className={classes.unselectable}>
                            <i>
                              <Moment
                                format="ddd MMM DD YYYY hh:mm:ss A"
                                date={publishedDate}
                              />
                            </i>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.row}>
                          {/* <Typography color="primary" variant={"body2"}> */}
                            <Chip variant="outlined" label={keywords} color="primary" />
                              {/* {keywords} */}
                            
                          {/* </Typography> */}
                        </Grid>
                        <Grid item xs={12} sm={12} className={classes.row}>
                          <ClipPlayer
                            clip={clip}
                            progressBar={true}
                            hideButton={true}
                            endTime={`${endMinutes}:${endSeconds}`}
                          />
                        </Grid>
                      </Grid>
                    {/* </div> */}
                  </CardContent>
                  {/* </Grid> 
            </Grid>   */}
                </div>
              </div>
            </Card>
          </div>
        )}
      </Draggable>
    );
  }

  onRemoveFromQueue = () => {
    this.props.onClipRemoved(this.props.clip);
  };

  pad = (num, size) => {
    var s = String(num);
    while (s.length < (size || 2)) {
      s = "0" + s;
    }
    return s;
  };
}

ClipQueueCard.propTypes = {
  clip: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  onClipRemoved: PropTypes.func.isRequired
};

export default withStyles(styles)(ClipQueueCard);
