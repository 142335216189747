import React from "react";

// COMPONENTS
import ClipEditor from '../common/clipEditor';

class ClipGenerator extends React.Component {

    render() {
        return (
            <div>
                <ClipEditor/>
            </div>
        );
    }

}

export default ClipGenerator;