import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
// MATERIAL-UI
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const styles = theme => ({
    card: {
        margin: theme.spacing.unit * 2
    },
    button: {
        marginLeft: "auto",
        margin: theme.spacing.unit,
        padding: "6px",
        verticalAlign: "bottom"
    },
    smallButton: {
        marginLeft: "auto",
        margin: theme.spacing.unit,
        padding: "6px",
        verticalAlign: "bottom",
        fontSize: "10px"
    },
    playerCard: {
        margin: theme.spacing.unit * 2,
        maxWidth: "600px"
    },
    tableRoot: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
});

class PlayerCardDemo extends Component {

    render() {

        const { classes } = this.props;

        let rows = [
            {'name': 'Carson Wentz', 'team': 'PHL', 'position': 'QB', 'rank': 1, 'player_rank': 'QB1'},
            {'name': 'Zach Ertz', 'team': 'PHL', 'position': 'TE', 'rank': 2, 'player_rank': 'TE1'},
            {'name': 'Alshon Jeffrey', 'team': 'PHL', 'position': 'WR', 'rank': 3, 'player_rank': 'WR1'},
            {'name': 'Miles Sanders', 'team': 'PHL', 'position': 'RB', 'rank': 4, 'player_rank': 'RB1'},
        ];

        return (
            <Grid item xs={12} align='center'>
                <Card className={classes.card}>
                    <CardContent>
                        <Grid
                            container
                            direction="column"
                        >
                            <Typography variant={'h5'} style={{textAlign: "center", marginBottom: "4px"}}>
                                Player Card Demo
                            </Typography>

                            <Typography variant={'p'} style={{textAlign: "center", marginBottom: "4px"}}>
                                Linking your website to Clipcast provides a powerful new tool for your users. They can
                                research players on your site with your content, but then quickly access relevant
                                podcast clips about a player or topic. Integration is very quick and usually only a
                                slight modification to your website.
                            </Typography>
                            <br/>
                            <Typography variant={'p'} style={{textAlign: "center", marginBottom: "4px"}}>
                                The simplest way to do this is to add a
                                button to your website that can send listeners directly to Clipcast with pre-loaded
                                search results.
                            </Typography>
                            <br/>
                            <Typography variant={'p'} style={{textAlign: "center", marginBottom: "4px"}}>
                                To do this, simply create a button on your website that when clicked will link to
                                Clipcast with a URL. The URL will contain the terms you want to search for in the
                                following format:
                            </Typography>

                            <br/>
                            <Typography variant={'p'} style={{textAlign: "center", marginBottom: "4px", color: "rgb(120, 120, 120)"}}>
                                https://clipcast.app/?keywords=[your search term]
                            </Typography>
                            <br/>
                            <Typography variant={'p'} style={{textAlign: "center", marginBottom: "4px"}}>
                                By replacing [your search term] with a team or player name, you can send your users to
                                Clipcast with pre-loaded results. You can programatically alter the button based on the
                                context of your page, filling in a player name if it is inside a player card for example.
                                Whatever text you put in the URL will be searched on when the user reaches Clipcast.
                                For example, this will search for Eagles:
                            </Typography>
                            <br/>
                            <Typography variant={'p'} style={{textAlign: "center", marginBottom: "4px", color: "rgb(120, 120, 120)"}}>
                                <a href="https://clipcast.app/?keywords=Eagles" target="_blank" rel="noopener noreferrer">
                                    https://clipcast.app/?keywords=Eagles
                                </a>
                            </Typography>
                            <br/>
                            <Typography variant={'p'} style={{textAlign: "center", marginBottom: "4px"}}>
                                No quotes or special characters are needed, the browser will URL encode multi-word
                                keywords. Here is another example for Carson Wentz:
                            </Typography>
                            <br/>
                            <Typography variant={'p'} style={{textAlign: "center", marginBottom: "4px", color: "rgb(120, 120, 120)"}}>
                                <a href="https://clipcast.app/?keywords=Carson Wentz" target="_blank" rel="noopener noreferrer">
                                    https://clipcast.app/?keywords=Carson Wentz
                                </a>
                            </Typography>
                            <br/>
                            <Typography variant={'p'} style={{textAlign: "center", marginBottom: "4px"}}>
                                Clipcast also allows podcasters to prefer their podcast show up in the results more often than
                                other podcasts. While it will not show only your podcast results, it will show a
                                majority of them coming from your podcasts. To enable this feature, pass in the
                                preferredPodcastId parameter with your podcast ID. To get your podcast ID, you can email
                                us at hello@clipcast.app. Below is an example of this showing the majority of results
                                from the Late Round podcast (you would need to substitute LATEROUND with your podcast id):
                            </Typography>
                            <br/>
                            <Typography variant={'p'} style={{textAlign: "center", marginBottom: "4px", color: "rgb(120, 120, 120)"}}>
                                <a href="https://clipcast.app/?keywords=Eagles&preferredPodcastId=LATEROUND" target="_blank" rel="noopener noreferrer">
                                    https://clipcast.app/?keywords=Eagles&preferredPodcastId=LATEROUND
                                </a>
                            </Typography>
                            <br/>
                            <Typography variant={'p'} style={{textAlign: "center", marginBottom: "4px"}}>
                                You can use the Clipcast logo in your button. The link to our preferred brand logo in
                                SVG format (which requires a specific font) is here:
                                <a href="https://clipcast.app/clipcast-horizontal.svg" target="_blank" rel="noopener noreferrer">
                                    https://clipcast.app/clipcast-horizontal.svg
                                </a>
                                <br/>
                                <br/>
                                PNG format (which does not require a specific font) is here:
                                <a href="https://clipcast.app/clipcast-horizontal.png" target="_blank" rel="noopener noreferrer">
                                    https://clipcast.app/clipcast-horizontal.png
                                </a>
                            </Typography>
                            <br/>
                            <Typography variant={'p'} style={{textAlign: "center", marginBottom: "4px"}}>
                                Below is an example of a player card with a Clipcast button.
                            </Typography>

                        </Grid>
                    </CardContent>
                </Card>
                <Card className={classes.playerCard}>
                    <CardContent>
                        <Grid
                            container
                            direction="column"
                        >
                            <Typography variant={'h5'} style={{textAlign: "center", marginBottom: "4px"}}>
                                Carson Wentz
                            </Typography>
                            
                            <Grid>
                                <Grid item xs={4} align='center'></Grid>
                                <Grid item xs={4} align='center'>

                                    <img alt="" src="https://d395i9ljze9h3x.cloudfront.net/req/20180910/images/headshots/WentCa00_2018.jpg"></img>
                                </Grid>
                            </Grid>

                            <Typography variant={'p'} style={{textAlign: "center", marginBottom: "4px", color: "rgb(120, 120, 120)"}}>
                                Position: QB Throws: Right
                            </Typography>
                            <Typography variant={'p'} style={{textAlign: "center", marginBottom: "4px", color: "rgb(120, 120, 120)"}}>
                                6' 5", 237lb (196cm, 107kg)
                            </Typography>
                            <Typography variant={'p'} style={{textAlign: "center", marginBottom: "4px", color: "rgb(120, 120, 120)"}}>
                                Born: December 30, 1992 (Age: 26-161d) in Raleigh, NC
                            </Typography>

                            <br/>

                            <Typography variant={'p'} style={{textAlign: "center", marginBottom: "4px"}}>
                                Carson James Wentz is an American professional football player. He plays as a
                                quarterback for the ‘Philadelphia Eagles’ in the ‘National Football League’ (NFL) and
                                is considered by many as one of the best young quarterbacks in the league.
                            </Typography>

                            <br/>

                            <a href="https://clipcast.app/?keywords=Carson Wentz" target="_blank" rel="noopener noreferrer">
                                <Button
                                    variant="outlined"
                                    className={classes.button}
                                >
                                    Listen on
                                    <img
                                        alt=""
                                        align="center"
                                        src={process.env.PUBLIC_URL + "/clipcast-horizontal.svg"}
                                        height="30px"
                                        style={{ marginLeft: "4px" }}
                                    />
                                </Button>
                            </a>

                        </Grid>
                    </CardContent>
                </Card>
                <Card className={classes.card}>
                    <CardContent>
                        <Grid
                            container
                            direction="column"
                        >
                            <Typography variant={'h5'} style={{textAlign: "center", marginBottom: "4px"}}>
                                Player Rankings Demo
                            </Typography>

                            <Typography variant={'p'} style={{textAlign: "center", marginBottom: "4px"}}>
                                The same method can be used to link nearly any player centric content to Clipcast. Below
                                is a rankings table example that can share Clipcast content with users for each player.
                            </Typography>

                            <div className={classes.tableRoot}>
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Rank</TableCell>
                                            <TableCell align="right">Name</TableCell>
                                            <TableCell align="right">Team</TableCell>
                                            <TableCell align="right">Position</TableCell>
                                            <TableCell align="right">Position Rank</TableCell>
                                            <TableCell align="right"> </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map(row => (
                                            <TableRow key={row.name}>
                                                <TableCell component="th" scope="row">
                                                    {row.rank}
                                                </TableCell>
                                                <TableCell align="right">{row.name}</TableCell>
                                                <TableCell align="right">{row.team}</TableCell>
                                                <TableCell align="right">{row.position}</TableCell>
                                                <TableCell align="right">{row.player_rank}</TableCell>
                                                <TableCell>
                                                    <a href={"https://clipcast.app/?keywords="+row.name} target="_blank" rel="noopener noreferrer">
                                                        <Button
                                                            variant="outlined"
                                                            className={classes.smallButton}
                                                        >
                                                            Listen on
                                                            <img
                                                                alt=""
                                                                align="center"
                                                                src={process.env.PUBLIC_URL + "/clipcast-horizontal.svg"}
                                                                height="14px"
                                                                style={{ marginLeft: "4px" }}
                                                            />
                                                        </Button>
                                                    </a>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </div>

                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        );
    }
}

export default withStyles(styles)(PlayerCardDemo);