

class AppStore {

    static fetchTags()
    {
        if (typeof(Storage) !== "undefined" && localStorage !== null) {
            var key = localStorage.getItem("tags");
            if (key != null) {
                return JSON.parse(key);
            }
        }
        return null;
    }

    static storeTags(tags)
    {
        if (typeof(Storage) !== "undefined" && localStorage !== null) {
            localStorage.setItem("tags", JSON.stringify(tags));
        }
    }

    static fetchUserInfo()
    {
        if (typeof(Storage) !== "undefined" && localStorage !== null) {
            var key = localStorage.getItem("userInfo");
            if (key != null) {
                return JSON.parse(key);
            }
        }
        return null;
    }

    static storeUserInfo(userInfo)
    {
        if (typeof(Storage) !== "undefined" && localStorage !== null) {
            localStorage.setItem("userInfo", JSON.stringify(userInfo));
        }
    }

}

export default AppStore;