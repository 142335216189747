import React from 'react';
// MATERIAL-UI
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// FETCH
import UserFetch from '../../fetch/userFetch';
// LIB
import ValidEmail from '../../lib/validEmail';
import PageState from './pageState';

class UserDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            availableTags: null,
            selectedTags: [],
            email: "",
            password: "",
            errorMessage: null,
            statusMessage: null,
            tosAccepted: false
        };
    };

    render() {
        let invalidEmail = !ValidEmail.validEmail(this.state.email);
        let invalidPassword = this.props.pageState !== PageState.FORGOT && this.state.password.length < 5;

        var title = this.props.pageState === PageState.CREATE ? "Create Account" :
            this.props.pageState === PageState.LOGIN ? "Login to Existing Account" : "Recover your Password";
        var buttonMessage = this.props.pageState === PageState.CREATE ? "Sign Up" :
            this.props.pageState === PageState.LOGIN ? "Login" : "Submit";


        return (
            <Dialog fullWidth maxWidth="sm" onClose={this.onClose} aria-labelledby="share-clip" open={this.props.open}>
                <DialogContent>
                    <Typography style={{textAlign: "center", marginBottom: "4px", color: "rgb(120, 120, 120)"}}>
                        {title}
                    </Typography>
                    <TextField
                        label="Email"
                        type="email"
                        margin="normal"
                        fullWidth
                        value={this.state.email}
                        onChange={(event)=>{this.setState({email: event.target.value})}}
                        onKeyPress={(ev) => {
                            if (ev.key === 'Enter') {
                              this.onButtonPressed();
                            }
                          }}
                    />
                    {this.props.pageState !== PageState.FORGOT &&
                        <TextField
                            label="Password"
                            type="password"
                            margin="normal"
                            fullWidth
                            value={this.state.password}
                            onChange={(event)=>{this.setState({password: event.target.value})}}
                            onKeyPress={(ev) => {
                                if (ev.key === 'Enter') {
                                  this.onButtonPressed();
                                }
                              }}
                        />
                    }
                    <Typography style={{textAlign: "center", marginBottom: "4px", color: "rgb(255, 120, 120)"}}>
                        {this.state.errorMessage}
                    </Typography>
                    <Typography style={{textAlign: "center", marginBottom: "4px"}}>
                        {this.state.statusMessage}
                    </Typography>
                    {this.props.pageState === PageState.LOGIN &&
                        <div style={{textAlign: "center"}}>
                            <Button color="primary" onClick={this.onForgotPasswordClick}>
                                Forgot Password?
                            </Button>
                        </div>
                    }
                    {this.props.pageState === PageState.CREATE &&
                        <div style={{textAlign: "center"}}>
                            <FormControlLabel control={<Checkbox
                                checked={this.state.tosAccepted}
                                onClick={() => {this.setState({tosAccepted: !this.state.tosAccepted})}}
                            />} label={<p>I accept the <a href='/terms-of-service' target='_blank'>terms of service</a> </p>} />
                        </div>
                    }
                    <DialogActions>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.onButtonPressed}
                            style={{marginTop: "20px"}}
                            fullWidth
                            disabled={invalidEmail || invalidPassword || (this.props.pageState === PageState.CREATE && !this.state.tosAccepted)}
                        >
                            {buttonMessage}
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        )
    }

    onButtonPressed = () => {
        if (this.props.pageState === PageState.CREATE) {
            this.setState({statusMessage: null, errorMessage: null}, () => {
                UserFetch.createUser(this.state.email, this.state.password).then(() => {
                    UserFetch.userLogin(this.state.email, this.state.password).then(() => {
                        this.props.onClose();
                    });
                }).catch((error) => {
                    this.setState({errorMessage: "Unable to create account. Email address already in use."})
                });
            });
        } else if (this.props.pageState === PageState.LOGIN) {
            this.setState({statusMessage: null, errorMessage: null}, () => {
                UserFetch.userLogin(this.state.email, this.state.password).then(() => {
                    this.props.onClose();
                }).catch((error) => {
                    this.setState({errorMessage: "Unable to log in. Check email and password and try again."})
                });
            });
        } else if (this.props.pageState === PageState.FORGOT) {
            this.setState({statusMessage: null, errorMessage: null}, () => {
                UserFetch.forgotPasswordInitiate(this.state.email).then(() => {
                    this.setState({statusMessage: "An email has been sent with instructions to reset your password."})
                }).catch((error) => {
                    this.setState({errorMessage: "Unable to reset password. Check email and try again."})
                });
            });
        }
    };

    onClose = () => {
        this.setState({statusMessage: null, errorMessage: null}, () => {
            this.props.onClose();
        })
    }

    onForgotPasswordClick = () => {
        if (this.props.onForgetPasswordClicked) {
            this.props.onForgetPasswordClicked();
        }
    };

    onTagsChanged = (tags) => {
        this.setState({selectedTags: tags});
    };
}

export default UserDialog;