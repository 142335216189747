import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
// MATERIAL-UI
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// COMPONENTS
import ManagePodcasts from './ManagePodcasts';
import ManageSubscription from './ManageSubscription';
import DashboardAnalytics from './DashboardAnalytics';
import ClipGenerator from './ClipGenerator';
import TutorialVideos from './TutorialVideos';

const styles = theme => ({
});

class PortalDashboard extends Component {

    state = {
        tabValue: 0
    };

    render() {

        return (
            <div>
                <Tabs
                    centered
                    value={this.state.tabValue}
                    onChange={(event, newValue) => this.setState({tabValue: newValue})}
                >
                    <Tab label="Podcasts" />
                    <Tab label="Subscription" />
                    <Tab label="Analytics" />
                    <Tab label="Generator" />
                    <Tab label="Tutorials" />
                </Tabs>
                {this.state.tabValue === 0 && <ManagePodcasts/>}
                {this.state.tabValue === 1 && <ManageSubscription/>}
                {this.state.tabValue === 2 && <DashboardAnalytics/>}
                {this.state.tabValue === 3 && <ClipGenerator/>}
                {this.state.tabValue === 4 && <TutorialVideos/>}
            </div>
        );
    }
}

export default withStyles(styles)(PortalDashboard);