import AppConfig from '../appConfig'
import AbstractFetch from './abstractFetch';

class UserFetch {

    static getUserInfo() {

        var url = AppConfig.apiUrl+"/user/info";
        return AbstractFetch.fetch(url, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            return response.json()
        })
    }

    static userLogin(email, password) {

        var url = AppConfig.apiUrl+"/user/login";
        return AbstractFetch.fetch(url, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({"email": email, "password": password})
        }).then((response) => {
            return response.json()
        })
    }

    static userLogout(email) {

        var url = AppConfig.apiUrl+"/user/logout";
        return AbstractFetch.fetch(url, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({"email": email})
        }).then((response) => {
                return response.json()
            })
    }

    static createUser(email, password) {

        var url = AppConfig.apiUrl+"/user";
        return AbstractFetch.fetch(url, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({"email": email, "password": password})
        }).then((response) => {
                return response.json()
            })
    }

    static feedsToHoldingTank(params) {

        var url = AppConfig.apiUrl+"/user/holdingtank";
        return AbstractFetch.fetch(url, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
        }).then((response) => {
            return response.json()
        })
    }

    static getFeedsFromHoldingTank(code) {

        var url = AppConfig.apiUrl+"/user/holdingtank/"+code;
        return AbstractFetch.fetch(url, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            return response.json()
        })
    }

    static addUserSubscription(subscription_id) {

        var url = AppConfig.apiUrl+"/user/subscription";
        return AbstractFetch.fetch(url, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'subscription_id': subscription_id
            })
        }).then((response) => {
            return response.json()
        })
    }

    static getSubscription(code) {

        var url = AppConfig.apiUrl+"/user/subscription";
        return AbstractFetch.fetch(url, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            return response.json()
        })
    }

    static getUnbilled(code) {

        var url = AppConfig.apiUrl+"/user/unbilled_charges";
        return AbstractFetch.fetch(url, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            return response.json()
        })
    }

    static getPodcasts() {

        var url = AppConfig.apiUrl+"/user/podcast";
        return AbstractFetch.fetch(url, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            return response.json()
        })
    }

    static putPodcast(feed) {

        var url = AppConfig.apiUrl+"/user/podcast";
        return AbstractFetch.fetch(url, {
            credentials: 'include',
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'feed': feed
            })
        }).then((response) => {
            return response.json()
        })
    }

    static deletePodcast(id) {

        var url = AppConfig.apiUrl+"/user/podcast/"+id;
        return AbstractFetch.fetch(url, {
            credentials: 'include',
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            return response.json()
        })
    }

    static getPodcastEventAnalytics(podcast_id, days) {

        var url = AppConfig.apiUrl+"/user/podcast/"+podcast_id+"/analytics/event";
        if (days) {
            url+="?days=" + days
        }
        return AbstractFetch.fetch(url, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            return response.json()
        })
    }

    static forgotPasswordInitiate(email) {

        var url = AppConfig.apiUrl+"/user/forgotpassword/initiate";
        return AbstractFetch.fetch(url, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'email': email
            })
        }).then((response) => {
            return response.json()
        })
    }

    static forgotPasswordComplete(email, code, password) {

        var url = AppConfig.apiUrl+"/user/forgotpassword/complete";
        return AbstractFetch.fetch(url, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'email': email,
                'code': code,
                'password': password,
            })
        }).then((response) => {
            return response.json()
        })
    }

    static postUserFeedback(message) {

        var url = AppConfig.apiUrl+"/user/feedback";
        return AbstractFetch.fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'message': message,
            })
        }).then((response) => {
            return response.json()
        })
    }

    static unsubscribeUser(user_id) {
        console.log(user_id)
        var url = AppConfig.apiUrl+"/user/email/unsubscribe";
        return AbstractFetch.fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'user_id': user_id,
            })
        }).then((response) => {
            return response.json()
        })
    }

}

export default UserFetch;