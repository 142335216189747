import React from 'react';

import ClipsFetchs from '../../fetch/clipsFetch';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Button from '@material-ui/core/Button';

import Select from 'react-select';


class TagsSearch extends React.Component {

    render() {
        if (!this.props.availableTags) {
            return null;
        }

        let options = this.props.availableTags.map((tag) => {
            return {
                value: tag,
                label: tag
            }
        });

        return (
            <div>
                <div>
                    {this.props.filterLabel && <p>{this.props.filterLabel}</p>}
                    <Select
                        maxMenuHeight="200"
                        isMulti={true}
                        onChange={this.onTagSelected}
                        options={options}
                        value={this.props.selectedTags}
                    />

                </div>
            </div>
        )
    }

    onTagSelected = (event) => {
        this.props.onTagsChanged(event.map((tag) => {return tag}));
    }

}

class ClipFilters extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            availableTags: null,
            selectedTags: [],
            searchUserPodcasts: false
        };
    };

    render() {
        return (
            <Dialog fullWidth={true} onClose={this.props.onClose} aria-labelledby="share-clip" open={this.props.open}>
                <DialogContent>
                    <div style={{minHeight: "250px"}}>
                        <p>
                            <b>Filters</b>
                        </p>
                        <TagsSearch
                            onTagsChanged={this.onTagsChanged}
                            availableTags={this.state.availableTags}
                            selectedTags={this.state.selectedTags}
                            filterLabel={this.props.filterLabel}
                        />
                    </div>
                    {this.props.showAllSearchOptions &&
                        <div>
                            { this.props.userHasPodcasts &&
                                <FormControlLabel control={<Checkbox checked={this.state.searchUserPodcasts}
                                onClick={() => {this.setState({searchUserPodcasts: !this.state.searchUserPodcasts})}}/>}
                                              label="Search Only My Podcasts"/>
                            }
                        </div>
                    }
                    <DialogActions>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.onSave}
                            style={{marginTop: "20px"}}
                        >
                            Save
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        )
    }

    componentDidMount() {
        ClipsFetchs.fetchTags().then((tags) => {
            this.setState({availableTags: tags['tags']})
        }).catch((error) => {
            console.log('unable to fetch list')
        });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.tags === prevProps.tags && this.props.searchUserPodcasts === prevProps.searchUserPodcasts) {
            return;
        }
        
        if (this.props.tags) {
            let tagsForUI = this.props.tags.map((tag) => {return {value: tag, label: tag};});
            this.setState({selectedTags: tagsForUI, searchUserPodcasts: this.props.searchUserPodcasts})
        } else {
            this.setState({searchUserPodcasts: this.props.searchUserPodcasts})
        }
    }

    onSave = () => {
        let tags = this.state.selectedTags.map((tag) => {return tag.value});
        if (this.props.setTags) {this.props.setTags(tags);}
        if (this.props.setCriteria) {
            this.props.setCriteria(tags, this.state.searchUserPodcasts)
        }
        this.props.onClose();
    };

    onTagsChanged = (tags) => {
        this.setState({selectedTags: tags});
    };
}

export default ClipFilters;